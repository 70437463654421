import { Component, OnInit, ɵConsole } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../../_helpers/must-match.validator';
import { environment } from "../../../environments/environment";
import { empty } from 'rxjs';

@Component({
  selector: 'app-usersform',
  templateUrl: './usersform.component.html',
  styleUrls: ['./usersform.component.scss']
})
export class UsersformComponent implements OnInit {

  userForm: FormGroup;
  filetoupload: File = null;
  imgurl: string = "assets/img/client_profile/default_profile.jpg";
  submitted = false;
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
  data: any;
  edit_id: number;
  profile_pic: any;
  updatebtn: boolean = false;
  details: Array<any>;
  city: Array<any>;
  state: Array<any>;
  street: string;
  zipcode: number;
  source: string;
  isupdate: boolean = false;
  password: any;
  confirmPassword: any;
  selectedFile  : File ;
  minNum : number =  6;
  email_existed : boolean = false;
  constructor(private route: ActivatedRoute, private CommonService: CommonService, private ToasterService: ToasterService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      street: [''],
      city: [''],
      state: [''],
      phone: ['', Validators.required],
      role: ['', Validators.required],
      zipcode: [''],
      password: ['', [Validators.required ]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });

    this.route.queryParams
      .subscribe(params => {
        if (params.action == 'update') {
          this.isupdate = true;
          this.edit_id = params.edit_id;
          this.getRecord();
        }
      });
  }





//   emailvalidation(control: AbstractControl){
//     if(control){
//       const group = <FormGroup>control.root.get('email');
//       if(group){
//         const addControl = group.controls.email; 
//         if(addControl){
// alert("asjdhoasdkas");
//         }
         
//       }
//     }
//   }









  handelFileinput(event , file: FileList) {
    this.filetoupload = file.item(0);
    // show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgurl = event.target.result;
    }
    reader.readAsDataURL(this.filetoupload);
    this.selectedFile = event.target.files[0]
}




  getRecord() {
    
    this.CommonService.getdetails(this.edit_id, 'user/').subscribe(data => {
      this.details = data;
      this.edit_id = this.details[0]['user_id'];
      this.first_name = this.details[0]['first_name'];
      this.last_name = this.details[0]['last_name'];
      if(this.details[0]['avtar_url']){
      this.imgurl = this.details[0]['avtar_url'];

      }

      this.userForm = this.formBuilder.group({
        first_name: [this.details[0]['first_name'], Validators.required],
        last_name: [this.details[0]['last_name'], Validators.required],
        email: [this.details[0]['email'], [Validators.required, Validators.email ]],
        phone: [this.details[0]['phone'], [Validators.required]],
        // profilepic: [this.details[0]['image']],
        zipcode: [this.details[0]['zipcode']],
        state: [this.details[0]['state']],
        city: [this.details[0]['city']],
        street: [this.details[0]['street']],
        role: [this.details[0]['role'], [Validators.required]],
        password: [this.details[0]['password'], [Validators.required]],
        confirmPassword: [this.details[0]['password'], [Validators.required]],
        is_active: [this.details[0]['is_active']],
      });
    });
  }







  get f() { return this.userForm.controls; }
  // onSearchChange(email : string ) {  
  //   var data = JSON.stringify({
  //     'email':email,
  //   });
  //   this.CommonService.duplicate_user_email(data , 'user/validate_email').subscribe(result => {
  //       console.log(result);

  //       this.userForm.errors.unique == true
        
  //       this.submitted = true;
  //   });
  // }


  onSubmit() {

    if (this.userForm.valid) {
      var data = JSON.stringify({
        'first_name': this.userForm.value.first_name,
        'last_name': this.userForm.value.last_name,
        'email': this.userForm.value.email,
        // 'image': this.userForm.value.profilepic,
        'password': this.userForm.value.password,
        'street': this.userForm.value.street,
        'city': this.userForm.value.city,
        'state': this.userForm.value.state,
        'phone': this.userForm.value.phone,
        'role': this.userForm.value.role,
        'zipcode': this.userForm.value.zipcode,
        'is_active': 1,
      });


      const uploadData = new FormData();

      if(this.selectedFile){
      uploadData.append('image', this.selectedFile, this.selectedFile.name);
      }



      var email_data = JSON.stringify({
        'email': this.userForm.value.email
      });


      this.CommonService.email_exist(data, 'user/validate_email').subscribe(result => {


        if(result.status == false){

        


      this.CommonService.insert(data, 'user/save').subscribe(result => {
        
        if (result) {
          if(this.selectedFile){
          uploadData.append('user_id', result.insertId);
          this.CommonService.uploadImage(uploadData, 'user/upload_image').subscribe(result => {
          
        });

      }

        this.ToasterService.success_toster(this.userForm.value.first_name + ' created successfully');
          this.router.navigate(['/users']);

        }
        else {
          this.ToasterService.error_toster(' Error while creating a client , Please try again');
        }
      });

    }
    else{
      this.email_existed = true;
    }




      
    });




    }
    else {
      console.log("validation false");
    }
    this.submitted = true;
  }

  updateCustomer(user_id) {
    if (this.userForm.valid) {
      this.updateDetails(user_id);
    }
    else {
      console.log("validation false");
    }
    this.submitted = true;
  }

  updateDetails(user_id) {
    var data = JSON.stringify({
      'first_name': this.userForm.value.first_name,
      'last_name': this.userForm.value.last_name,
      'email': this.userForm.value.email,
      'street': this.userForm.value.street,
      'city': this.userForm.value.city,
      'state': this.userForm.value.state,
      'role': this.userForm.value.role,
      'zipcode': this.userForm.value.zipcode,
      'phone': this.userForm.value.phone,
      'is_active': this.userForm.value.is_active,
    });

    const uploadData = new FormData();
    if(this.selectedFile){
      uploadData.append('image', this.selectedFile, this.selectedFile.name);
    }


    this.CommonService.update(data, 'user/update/' + user_id).subscribe(result => {
      if (result) {
        if(this.selectedFile){
        uploadData.append('user_id', user_id);
        this.CommonService.uploadImage(uploadData, 'user/upload_image').subscribe(result => {
        });
      }

      this.ToasterService.success_toster(this.userForm.value.first_name + ' Updated successfully');
      this.router.navigate(['/users']);


      }
      else {
        this.ToasterService.error_toster(' Error while Updating a client details , Please try again');
      }
    });
  }



}
