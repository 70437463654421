import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {ToasterService} from '../../services/toaster.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-customerorder',
    templateUrl: './customerorder.component.html',
    styleUrls: ['./customerorder.component.scss']
})
export class CustomerorderComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    url: any;
    order_id: any;
    customer_id: any;
    ordersproductslist: any;
    attachments: any;
    client: any;
    client_name: any;
    client_id: number;
    assign_user: number;
    API_URL = environment.APIBaseURL;
    allDesigners: any = [];

    print_all_qr_code_url: any;
    baseURL = environment.APIBaseURL;
    user_role = localStorage.getItem('user_role');
    user_id = localStorage.getItem('user_id');

    designer: any;
    sionnah_order_no: any;
    client_order_no: any;
    order_date: any;
    order_remark: any;

    imgurls: string;

    uploadImageOptions = {
        items: 1,
        margin: 0,
        dots: false,
        nav: true,
        navText: [
            '<button type="button" class="btn slider-left-btn"><i class="fa fa-angle-left"></i></button>',
            '<button type="button" class="btn slider-right-btn"><i class="fa fa-angle-right"></i></button>'
        ],
        autoplay: false,
        // loop: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            769: {
                items: 1
            }
        }
    };

    enable_dropzone_div: boolean = true;
    enable_view_image_div: boolean = false;
    imageData: any = [];
    btn_text: string = 'View Image';

    constructor(private route: ActivatedRoute,
                private CommonService: CommonService,
                private ToasterService: ToasterService,
                private formBuilder: FormBuilder,
                private router: Router) {
    }

    ngOnInit() {
        this.allDesigner();

        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(['/login']);
        }

        // this.get_orders_by_customers();
        this.dtOptions = {
            responsive: true
        };

        this.route.queryParams.subscribe(params => {
            if (params.action == 'update') {
                // this.isupdate = true;
                this.order_id = params.order_id;
                if (params.customer_id) {
                    this.customer_id = params.customer_id;
                }
                if (params.assign_user) {
                    this.assign_user = params.assign_user;
                }
                this.get_orders_by_customers();
            }
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    get_orders_by_customers() {
        console.log('here');
        if (this.customer_id) {
            this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(
                customer_details => {
                    this.client = customer_details[0];
                    console.log(this.client);
                    this.client_name = this.client.first_name + ' ' + this.client.last_name;
                }
            );
        }

        this.url = 'customer_orders/';
        this.CommonService.orders_by_customers(this.order_id, this.url).subscribe(
            data => {
                console.log(data);
                this.ordersproductslist = data.results;

                if (data.attachments) {
                    this.imgurls = data.attachments;
                    this.attachments = data.attachments;
                    console.log(this.imgurls);
                }

                this.sionnah_order_no = this.ordersproductslist[0].sionnah_order_no;
                this.client_order_no = this.ordersproductslist[0].client_order_no;
                this.order_date = this.ordersproductslist[0].order_date;
                this.order_remark = this.ordersproductslist[0].order_remark;

                this.generate_qr_code_url(this.ordersproductslist);
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
            }
        );
        this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(
            customer_details => {
                this.client = customer_details;
            }
        );
    }

    allDesigner() {
        this.url = 'user/role/';
        this.CommonService.getallrecords_by_role(this.url, 'Designer').subscribe(
            data => {
                this.allDesigners = data;
            }
        );
    }

    selectDesigner(event) {
        this.assign_user = event;
    }

    assignOrder() {
        var data = {
            user_id: this.assign_user
        };
        this.CommonService.update(
            data,
            'customer_orders/update/' + this.order_id
        ).subscribe(result => {

            console.log(result);
            var data = {
                'user_id': this.assign_user,
                'user_role': 'Designer',
                // 'tracking_id' : this.order_item_details.tracking_id,
                'order_id': this.order_id,
            };

            this.CommonService.update(data, 'order/track/update/status/order_id/' + this.order_id).subscribe(result => {
                if (result) {
                    this.ToasterService.success_toster('Order assign successfully');
                    this.router.navigate(['/orderslist']);
                } else {
                    this.ToasterService.error_toster(
                        ' Error while assigning order, Please try again'
                    );
                }
            });

        });
    }

    generate_qr_code_url(products) {
        this.print_all_qr_code_url =
            this.baseURL + 'qrcode/print_order_qr_code?id=';
        var params = [];
        products.forEach(function (singleRecord) {
            if (singleRecord.delivery_status !== 'Cancelled') {
                params.push(singleRecord.order_item_id);
            }
        });
        this.print_all_qr_code_url = this.print_all_qr_code_url + params;
    }

    save_order_info(order_id) {
        var sionnah_order_no = $('#sionnah_order_no').val();
        var client_order_no = $('#client_order_no').val();
        var order_date = $('#order_date').val();
        var order_remark = $('#order_remark').val();
        var sionnah_order_no = $('#sionnah_order_no').val();

        if(!sionnah_order_no) {
            sionnah_order_no = this.sionnah_order_no;
        }
        if(!client_order_no) {
            client_order_no = this.client_order_no;
        }
        if(!order_date) {
            order_date = this.order_date;
        }
        if(!order_remark) {
            order_remark = this.order_remark;
        }

        var data = {
            sionnah_order_no: sionnah_order_no,
            client_order_no: client_order_no,
            order_date: order_date,
            order_remark: order_remark
        };

        this.CommonService.update(
            data,
            'customer_orders/update/' + order_id
        ).subscribe(result => {
            console.log(result);
            if (result) {
                this.ToasterService.success_toster('Order Details Updated successfully');
                $('#sionnah_order_no').val('');
                $('#client_order_no').val('');
                $('#order_date').val('');
                $('#order_remark').val('');
                this.get_orders_by_customers();
            } else {
                this.ToasterService.error_toster(
                    ' Opps Something Went Wrong, Please try again'
                );
            }
        });
    }

    print_order() {
        window.print();
    }

    delete(order_item_id) {
        var data = {
            order_item_id: order_item_id,
            order_id: this.order_id,
            user_id: this.user_id,
            delivery_status: 'Cancelled'
        };
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to cancel this',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, Please cancel'
        }).then(result => {
            if (result.value) {
                this.CommonService.update(data, 'customer_orders/delete/order_item/' + order_item_id).subscribe(
                    data => {
                        Swal.fire(
                            'Deleted!',
                            'Your Order Item has been Cancelled.',
                            'success'
                        );
                        this.get_orders_by_customers();
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Order Item is safe :)',
                    'error'
                );
            }
        });
    }

    recover(order_item_id) {
        var data = {
            order_item_id: order_item_id,
            order_id: this.order_id,
            user_id: this.user_id,
            delivery_status: this.user_role
        };
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to Recover this',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, recover it!',
            cancelButtonText: 'No, Please cancel'
        }).then(result => {
            if (result.value) {
                this.CommonService.update(data, 'customer_orders/recover/order_item/' + order_item_id).subscribe(
                    data => {
                        Swal.fire(
                            'Recovred!',
                            'Your Order Item has been Recovered.',
                            'success'
                        );
                        console.log(data);
                        this.get_orders_by_customers();
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'error'
                );
            }
        });
    }

    onFilesAdded(files: File[]) {
        this.imageData = files;
        console.log(this.imageData);

        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = (e: ProgressEvent) => {
                const content = (e.target as FileReader).result;
            };

            reader.readAsDataURL(file);
        });
    }

    uploadImage() {
        this.enableViewImageDiv();
        const uploadData = new FormData();
            if (this.imageData) {
                console.log(this.imageData.length);
                for (let i = 0; i < this.imageData.length; i++) {
                    uploadData.append("image", this.imageData[i]);
                }
            }
            if (this.imageData.length > 0) {
                uploadData.append("order_id", this.order_id);
                this.CommonService.uploadImage(
                    uploadData,
                    "customer_orders/upload_image"
                ).subscribe(result => {
                    this.get_orders_by_customers();
                });
            }
    }

    deleteAttachments(imageId, order_id) {
        this.CommonService.deleteProductImages(
            imageId,
            "customer_orders/delete_image/"
        ).subscribe(data => {
            console.log(data);
            this.get_orders_by_customers();
        });
    }

    getButtonTitle($event) {
        console.log($event.target.title);
        if($event.target.title === 'View Image') {
            this.enableViewImageDiv();
        }
        else if($event.target.title === 'Upload Image') {
            this.enableUploadImageDiv();
        }
        else {
            this.enableUploadImageDiv();
        }
    }

    enableModal() {
        this.enableUploadImageDiv();
    }

    enableViewImageDiv() {
        this.enable_dropzone_div=false;
        this.enable_view_image_div=true;
        this.btn_text = 'Upload';
    }

    enableUploadImageDiv() {
        this.enable_dropzone_div=true;
        this.enable_view_image_div=false;
        this.btn_text = 'View Image';
    }

}