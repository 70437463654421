import { Component, OnInit, Input } from "@angular/core";
import { LoginService } from "../../services/login.service";
import { resolve } from "q";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  userdata: any;
  loadAPI: any;
  collapsed: boolean = false;
  orderCollapsed: boolean = false;

  constructor(public loginService: LoginService) {}

  ngOnInit() {
    this.loginService.setLoginData();
    this.setSidebar();
  }

  enableSubmenu() {
    this.collapsed = !this.collapsed;
  }

  enableOrdersSubmenu() {
    this.orderCollapsed = !this.orderCollapsed;
  }

  setSidebar() {
    this.userdata = this.loginService.getLoginData();
  }
}
