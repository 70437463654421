import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-userspecificorder",
  templateUrl: "./userspecificorder.component.html",
  styleUrls: ["./userspecificorder.component.scss"]
})
export class UserspecificorderComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  url: any;
  order_id: any;
  user_id: any;
  ordersproductslist: any;
  assignOrders: Array<any> = [];
  API_URL = environment.APIBaseURL;

  constructor(
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private ToasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }

    // this.get_orders_by_customers();
    this.dtOptions = {
      responsive: true
    };

    this.user_id = localStorage.getItem("user_id");
    this.get_assign_orders();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  get_assign_orders() {
    this.CommonService.getdetails(
      this.user_id,
      "customer_orders/user/"
    ).subscribe(user_orders => {
      this.assignOrders = user_orders;
      console.log(this.assignOrders);
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next({
          responsive: true
        });
      });
    });
  }
}
