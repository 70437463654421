import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy
} from "@angular/core";
import {CommonService} from "../../services/common.service";
import {ToasterService} from "../../services/toaster.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-ordertrack',
    templateUrl: './ordertrack.component.html',
    styleUrls: ['./ordertrack.component.scss']
})
export class OrdertrackComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    url: any;
    order_id: any;
    customer_id: any;
    ordersproductslist: any;
    client: any;
    client_name: any;
    client_id: number;

    baseURL = environment.APIBaseURL;
    order_item_log_details: any;

    constructor(private route: ActivatedRoute,
                private CommonService: CommonService,
                private ToasterService: ToasterService,
                private formBuilder: FormBuilder,
                private router: Router) {
    }

    ngOnInit() {

        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        // this.get_orders_by_customers();
        this.dtOptions = {
            responsive: true
        };

        this.route.queryParams.subscribe(params => {
            this.get_order_item_track_details(params.order_item_id, params.customer_id);
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }


    get_order_item_track_details(order_item_id, customer_id) {

        this.CommonService.getdetails(customer_id, "customer/").subscribe(
            customer_details => {
                this.client = customer_details[0];
                this.client_name = this.client.first_name + " " + this.client.last_name;
            }
        );

        this.CommonService.getdetails(order_item_id, "order/track/order_item_track_details/").subscribe(
            results => {
                this.order_item_log_details = results;

                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
            })
    }
}
