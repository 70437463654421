import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import { LoginService } from "../../services/login.service";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  userdata: any;
  miniNavbar: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private LoginService: LoginService,
    private ToasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.userdata = {
      user_id: localStorage.getItem("user_id"),
      user_role: localStorage.getItem("user_role"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
      token: localStorage.getItem("token")
    };
  }

  SmoothlyMenu() {
    this.miniNavbar = !this.miniNavbar;
    if (isPlatformBrowser(this.platform)) {
      $("#body").toggleClass("mini-navbar");
      // if (
      //   !$("body").hasClass("mini-navbar") ||
      //   $("body").hasClass("body-small")
      // ) {
      //   // Hide menu in order to smoothly turn on when maximize menu
      //   $("#side-menu").hide();
      //   // For smoothly turn on menu
      //   setTimeout(function() {
      //     $("#side-menu").fadeIn(400);
      //   }, 200);
      // } else if ($("body").hasClass("fixed-sidebar")) {
      //   $("#side-menu").hide();
      //   setTimeout(function() {
      //     $("#side-menu").fadeIn(400);
      //   }, 100);
      // } else {
      //   // Remove all inline style from jquery fadeIn function to reset menu state
      //   $("#side-menu").removeAttr("style");
      // }
    }
  }

  logout() {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    this.ToasterService.success_toster("logout successfully");
    this.LoginService.check_logout();
    this.router.navigate(["/login"]);
  }
}
