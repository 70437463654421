import { Injectable } from '@angular/core';
import { ProductService } from '../services/product.service';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { HttpConfigService } from "./http-config.service";
import "rxjs";

declare var toastr:any;
declare var swal:any;

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  client_id : any;
  msg : any;
  customerdeleteurl : string;
  constructor( private productService: ProductService , private http: HttpClient, private httpConfigService: HttpConfigService ) { }

  success_toster(msg){
    toastr.options = {
      closeButton: true,
      progressBar: true,
      showMethod: 'slideDown',
      timeOut: 4000
    };
    toastr.success(msg);
  }

  error_toster(msg){
    toastr.options = {
      closeButton: true,
      progressBar: true,
      showMethod: 'slideDown',
      timeOut: 4000
    };
    toastr.error(msg);
  }

  



  delete(id, url) {
    console.log(url);
    const API_URL = `${this.httpConfigService.baseURL}`+id;
    return this.http
      .delete<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  

}
