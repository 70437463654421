import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { HttpConfigService } from "./http-config.service";
import "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private httpConfigService: HttpConfigService) { }
  url : string;
  // Get all products
  allProducts() {
    const API_URL = environment.APIBaseURL+'product/all';
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  insert(data,url){
    const API_URL = environment.APIBaseURL+url;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  delete(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}`+url+id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getAll(data,url){
    const API_URL = environment.APIBaseURL+url;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getallrecords(url) {
    const API_URL = `${this.httpConfigService.baseURL}`+url;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getdetails(client_id , url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url +client_id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  } 

  
  scanned(url,order_item_id) {
    const API_URL = `${this.httpConfigService.baseURL}` + url +order_item_id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  } 



}
