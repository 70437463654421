import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {CommonService} from '../../../services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'


@Component({
    selector: 'app-dispatchlist',
    templateUrl: 'dispatchlist.component.html',
    styleUrls: ['dispatchlist.component.scss']
})
export class DispatchlistComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtOptions: any = {};
    dtOptions2: any = {};
    dtTrigger: Subject<any> = new Subject();
    url: string;
    alldispatcherlist: Array<any>;
    role: string;
    deleteurl: string;
    dispatchername: string;
    dispatcher_id: number;
    deleted: any;

    constructor(private CommonService: CommonService) {
    }

    ngOnInit() {
        this.dtOptions = {
            responsive: true
        }
        this.allembr();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }


    allembr() {
        this.url = "user/role/";
        this.role = "Dispatch";
        this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(data => {
            this.alldispatcherlist = data;
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next({
                    responsive: true
                });
            });
        });
    }

    delete_this(dispatcher_id, first_name, last_name) {
        this.dispatchername = first_name + " " + last_name;
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete ' + this.dispatchername,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, Please cancel'
        }).then((result) => {
            if (result.value) {
                this.deleteurl = 'user/delete/';
                this.dispatchername = first_name + " " + last_name;
                this.CommonService.delete(dispatcher_id, this.deleteurl).subscribe(data => {
                    Swal.fire(
                        'Deleted!',
                        'Your ' + this.dispatchername + 'has been deleted.',
                        'success'
                    )
                    this.allembr();
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your ' + this.dispatchername + ' is safe :)',
                    'error'
                )
            }
        });
    }


    recover(dispatcher_id, first_name, last_name) {
        this.dispatchername = first_name + " " + last_name;
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to recover ' + this.dispatchername,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, recover it!',
            cancelButtonText: 'No, Please cancel'
        }).then((result) => {
            if (result.value) {
                this.url = 'user/recover/';
                this.dispatchername = first_name + " " + last_name;
                this.CommonService.recover(dispatcher_id, this.url).subscribe(data => {
                    Swal.fire(
                        'Recovered!',
                        'Your ' + this.dispatchername + 'has been recovered',
                        'success'
                    )
                    this.allembr();
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'You cancelled process',
                    'error'
                )
            }
        });
    }


}
