import { Component, Input } from "@angular/core";
import { CommonService } from "../app/services/common.service";

@Component({
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  sidebar: any;
  header: any;

  constructor(public commonService: CommonService) {}

  title = "SIONNAH";
}
