import {
    AfterViewInit,
    Component,
    OnInit, OnDestroy, ViewChild,
} from "@angular/core";
import {CommonService} from "../services/common.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Router} from "@angular/router";

declare var $: any;

@Component({
    selector: 'app-allscantask',
    templateUrl: './allscantask.component.html',
    styleUrls: ['./allscantask.component.scss']
})
export class AllscantaskComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();
    scan_details: boolean = false;
    joblist: boolean = false;
    data: any;
    product: any;
    tasklist: any;
    localData: any;


    constructor(private CommonService: CommonService,
                private router: Router) {
    }

    ngOnInit() {
        this.dtOptions = {
            responsive: true
        };

        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }
        this.localData = {
            user_id: localStorage.getItem("user_id"),
            user_role: localStorage.getItem("user_role"),
            first_name: localStorage.getItem("first_name"),
            last_name: localStorage.getItem("last_name")
        };

        this.takslist();
    }
    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    takslist() {
        this.CommonService.tasklist(localStorage.getItem("user_id")).subscribe(
            result => {
                this.tasklist = result;
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });

                this.joblist = true;
                this.scan_details = false;
            }
        );
    }

}
