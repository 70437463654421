import { Injectable, Input, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { HttpConfigService } from "./http-config.service";
import "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  updated_login_value: EventEmitter<any> = new EventEmitter();
  userdata: any;

  public loginpage: boolean = false;

  constructor(
    private http: HttpClient,
    private httpConfigService: HttpConfigService
  ) {
    if (
      window.location.href.substr(window.location.href.lastIndexOf("/") + 1) ==
      "login"
    ) {
      this.loginpage = true;
    }

    if (
      window.location.href.substr(window.location.href.lastIndexOf("/") + 1) !=
      "login"
    ) {
      this.loginpage = false;
    }
  }

  check_login() {
    if (
      window.location.href.substr(window.location.href.lastIndexOf("/") + 1) !=
      "dashboard"
    ) {
      this.loginpage = false;
    }
  }

  check_logout() {
    if (
      window.location.href.substr(window.location.href.lastIndexOf("/") + 1) !=
      "login"
    ) {
      this.loginpage = true;
    }
  }

  login(data, url) {
    const API_URL = environment.APIBaseURL + url;

    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  setLoginData() {
    this.userdata = {
      user_id: localStorage.getItem("user_id"),
      user_role: localStorage.getItem("user_role"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name")
    };
    this.updated_login_value.emit(this.userdata);
  }

  getLoginData() {
    return this.userdata;
  }
}
