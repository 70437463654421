import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';

import Swal from 'sweetalert2/dist/sweetalert2.js'

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private CommonService: CommonService, private ToasterService: ToasterService) { }
  disc_value: number;
  price: number;
  percent_discount: number;
  discountForm: FormGroup;
  value: any;
  url: any;
  order_id: any;
  customer_id: any;
  client: string;
  client_name: string;
  ordersproductslist: any;
  invoice_data : any;

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        if (params.action == 'update') {
          // this.isupdate = true;
          this.order_id = params.order_id;
          this.customer_id = params.customer_id;
          this.get_orders_by_customers();
        }
      });

      var element = document.getElementById("body");
        element.classList.add("mini-navbar");
  }


  get_orders_by_customers() {
    this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(customer_details => {
      this.client = customer_details[0];
    });
    this.url = "customer_orders/";
    this.CommonService.orders_by_customers(this.order_id, this.url).subscribe(data => {
      this.ordersproductslist = data;
      // console.log(this.ordersproductslist);
    });
  }




  // public captureScreen() {

  //   var data = document.getElementById('contentToConvert');
  //   this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(customer_details => {
  //    var client = customer_details[0];
  //     html2canvas(data).then(canvas => {
  //       // Few necessary setting options
  //       var imgWidth = 208;
  //       var pageHeight = 295;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var heightLeft = imgHeight;
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
  //       var position = 0;
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //       pdf.save( this.client["first_name"]+ " " +this.client["last_name"] +".pdf"); // Generated PDF
  //     });
  //     // if (File.Exists(tempPathToSave))
  //     //  File.Delete(tempPathToSave);
  //     this.invoice_data = {
  //       'invoice_details': data,
  //     };
  //     this.CommonService.insert(this.invoice_data, "customer_order_invoice/save").subscribe(data => {
  //         console.log("return data",data);
  //     });
  //   });
  // }






  download_pdf(first_name,last_name ) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to download ' + first_name + " " + last_name +' pdf',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, download it!',
      cancelButtonText: 'No, Please cancel'
    }).then((result) => {
      if (result.value) {


        
    var data = document.getElementById('contentToConvert');
    this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(customer_details => {
     var client = customer_details[0];
      html2canvas(data).then(canvas => {
        // Few necessary setting options
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save( this.client["first_name"]+ " " +this.client["last_name"] +".pdf"); // Generated PDF
      });
      // if (File.Exists(tempPathToSave))
      //  File.Delete(tempPathToSave);
      this.invoice_data = {
        'invoice_details': data,
      };
      this.CommonService.insert(this.invoice_data, "customer_order_invoice/save").subscribe(data => {
          console.log("return data",data);
      });
    });


          Swal.fire(
            'generating '+ first_name + " " + last_name +' pdf',
          )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        )
      }
    });
  }



}