import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'


@Component({
  selector: 'app-embroderylist',
  templateUrl: './embroderylist.component.html',
  styleUrls: ['./embroderylist.component.scss']
})
export class EmbroderylistComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtTrigger: Subject<any> = new Subject();
  url: string;
  allembrlist: Array<any>;
  role: string;
  deleteurl: string;
  embrname: string;
  embr_id: number;
  deleted: any;

  constructor(private CommonService: CommonService) { }

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    }
    this.allembr();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  allembr() {
    this.url = "user/role/";
    this.role = "Embroidery";
    this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(data => {
      this.allembrlist = data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next({
          responsive: true
        });
      });
    });
  }

  delete_this(embr_id, first_name, last_name) {
    this.embrname = first_name + " " + last_name;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete ' + this.embrname,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, Please cancel'
    }).then((result) => {
      if (result.value) {
        this.deleteurl = 'user/delete/';
        this.embrname = first_name + " " + last_name;
        this.CommonService.delete(embr_id, this.deleteurl).subscribe(data => {
          Swal.fire(
            'Deleted!',
            'Your ' + this.embrname + 'has been deleted.',
            'success'
          )
          this.allembr();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your ' + this.embrname + ' is safe :)',
          'error'
        )
      }
    });
  }



  recover(embr_id, first_name, last_name) {
    this.embrname = first_name + " " + last_name;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to recover ' + this.embrname,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, recover it!',
      cancelButtonText: 'No, Please cancel'
    }).then((result) => {
      if (result.value) {
        this.url = 'user/recover/';
        this.embrname = first_name + " " + last_name;
        this.CommonService.recover(embr_id, this.url).subscribe(data => {
          Swal.fire(
            'Recovered!',
            'Your ' + this.embrname + 'has been recovered',
            'success'
          )
          this.allembr();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'You cancelled process',
          'error'
        )
      }
    });
  }


}
