import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {CommonService} from "../../../services/common.service";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
    selector: 'app-outsourcevendor',
    templateUrl: 'outsourcevendor.component.html',
    styleUrls: ['outsourcevendor.component.scss']
})
export class OutsourcevendorComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtOptions: any = {};
    dtOptions2: any = {};
    dtTrigger: Subject<any> = new Subject();
    url: string;
    managerlist: Array<any>;
    role: string;
    out_source_vendors: Array<any>;
    out_source_vendor: string;
    deleteurl: string;

    constructor(private CommonService: CommonService) {
    }

    ngOnInit() {
        this.dtOptions = {
            responsive: true
        };
        this.allout_source_vendor();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    allout_source_vendor() {
        this.url = "user/role/";
        this.role = "Out Source Vendor";
        this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(
            data => {
                this.out_source_vendors = data;
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
            }
        );
    }

    delete_this(out_source_vendor_id, first_name, last_name) {
        this.out_source_vendor = first_name + " " + last_name;
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete " + this.out_source_vendor,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, Please cancel"
        }).then(result => {
            if (result.value) {
                this.deleteurl = "user/delete/";
                this.out_source_vendor = first_name + " " + last_name;
                this.CommonService.delete(out_source_vendor_id, this.deleteurl).subscribe(
                    data => {
                        Swal.fire(
                            "Deleted!",
                            "Your " + this.out_source_vendor + "has been deleted.",
                            "success"
                        );
                        this.allout_source_vendor();
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    "Cancelled",
                    "Your " + this.out_source_vendor + " is safe :)",
                    "error"
                );
            }
        });
    }

    recover(out_source_vendor_id, first_name, last_name) {
        this.out_source_vendor = first_name + " " + last_name;
        Swal.fire({
            title: "Are you sure?",
            text: "You want to recover " + this.out_source_vendor,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, recover it!",
            cancelButtonText: "No, Please cancel"
        }).then(result => {
            if (result.value) {
                this.url = "user/recover/";
                this.out_source_vendor = first_name + " " + last_name;
                this.CommonService.recover(out_source_vendor_id, this.url).subscribe(data => {
                    Swal.fire(
                        "Recovered!",
                        "Your " + this.out_source_vendor + "has been recover.",
                        "success"
                    );
                    this.allout_source_vendor();
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your cancelled process", "error");
            }
        });
    }

}
