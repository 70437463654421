import {  Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.scss']
})
export class UserslistComponent implements  OnInit {

  constructor( private CommonService: CommonService, private router: Router) { }


  ngOnInit() {

    var result = this.CommonService.check_login();
    if( result ==  true ){
      this.router.navigate(['/login']);
    }
    
  }


}
