import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "../services/common.service";
import { LoginService } from "../services/login.service";
import { ToasterService } from "../services/toaster.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  userdata: any;

  constructor(
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private LoginService: LoginService,
    private ToasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    } else {
      if (localStorage.getItem("user_role") == "Manager") {
        this.router.navigate(["/dashboard"]);
      } else if (localStorage.getItem("user_role") == "Marketing") {
        this.router.navigate(["/orderslist"]);
      } else {
        this.router.navigate(["/scantask"]);
      }
    }

    this.loginForm = this.formBuilder.group({
      is_active: [""],
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });

    if (localStorage.getItem("user_id")) {
      this.userdata = {
        user_id: localStorage.getItem("user_id"),
        user_role: localStorage.getItem("user_role"),
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name")
      };
    } else {
      this.router.navigate(["/login"]);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.valid) {
      var data = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      };

      console.log(data);
      this.LoginService.login(data, "login/check_login").subscribe(result => {
        if (result.success == true) {
          this.ToasterService.success_toster(
            "Welocome " +
              result.first_name +
              ". you have logged in successfully."
          );
          localStorage.setItem("email", this.loginForm.value.email);
          localStorage.setItem("token", result.token);
          localStorage.setItem("user_id", result.user_id);
          localStorage.setItem("first_name", result.first_name);
          localStorage.setItem("last_name", result.last_name);
          localStorage.setItem("user_role", result.user_role);
          this.LoginService.check_login();
          this.LoginService.setLoginData();
          if (result.user_role.toLowerCase() == "manager") {
            this.router.navigate(["/dashboard"]);
          } else if (result.user_role.toLowerCase() == "marketing") {
            this.router.navigate(["/customerlist"]);
          } else {
            this.router.navigate(["/scantask"]);
          }
        } else if (result.success == false) {
          this.ToasterService.error_toster(result.message);
        }
      });
    } else {
      console.log("validation false");
    }
    this.submitted = true;
  }
}
