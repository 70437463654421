import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-managerlist',
  templateUrl: './managerlist.component.html',
  styleUrls: ['./managerlist.component.scss']
})
export class ManagerlistComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2 : any = {};
  dtTrigger: Subject<any> = new Subject();
  url: string;
  managerlist: Array<any>;
  role :  string;
  deleteurl: string;
  managername: string;
  manager_id: number;
  deleted : any;



  constructor(private CommonService: CommonService) { }

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    }
    this.allManagers();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  allManagers(){
    this.url = "user/role/";
    this.role = "Manager";
    this.CommonService.getallrecords_by_role(this.url,this.role).subscribe(data => {
      this.managerlist = data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next({
            responsive: true
        });
      });
    });
  }



  delete_this(manager_id,first_name,last_name) {
    this.managername = first_name + " " + last_name;
    Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete ' + this.managername,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, Please cancel'
    }).then((result) => {
        if (result.value) {
            this.deleteurl = 'user/delete/';
            this.managername = first_name + " " + last_name;
            this.CommonService.delete(manager_id, this.deleteurl).subscribe(data => {
                Swal.fire(
                    'Deleted!',
                    'Your ' + this.managername +'has been deleted.',
                    'success'
                )
                this.allManagers();
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'Your '+ this.managername+' is safe :)',
                'error'
            )
        }
    });
  }



  recover(manager_id,first_name,last_name) {
    this.managername = first_name + " " + last_name;
    Swal.fire({
        title: 'Are you sure?',
        text: 'You want to recover ' + this.managername,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, recover it!',
        cancelButtonText: 'No, Please cancel'
    }).then((result) => {
        if (result.value) {
            this.url = 'user/recover/';
            this.managername = first_name + " " + last_name;
            this.CommonService.recover(manager_id, this.url).subscribe(data => {
                Swal.fire(
                    'Recovered!',
                    'Your ' + this.managername +'has been recover.',
                    'success'
                )
                this.allManagers();
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'You cancelled process',
                'error'
            )
        }
    });
  }




}
