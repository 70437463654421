import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpConfigService {

  constructor() { }

  baseURL = environment.APIBaseURL;

  authToken: string;

  getHeaders() {
    let token: string;
      const httpOptions: object = {
        headers: new HttpHeaders({
          mode: "cors",
          "Content-Type": "application/json"
        })
      };
      return httpOptions;
  }
}
