import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild, PLATFORM_ID, Inject
} from "@angular/core";
import {CommonService} from "../services/common.service";
import {ToasterService} from "../services/toaster.service";
import {Router} from "@angular/router";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

declare var swal: any;
declare var $;

@Component({
    selector: "app-orders",
    templateUrl: "./delete-image.component.html",
    styleUrls: ["./delete-image.component.scss"]
})
export class DeleteImageComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    client_id: number;
    deleted: any;
    productRecords: any;
    search_records: any;
    response_from_search: any;
    url: string;
    user_role = localStorage.getItem("user_role");
    status_access: boolean = false;
    selectedAllCheckboxes = [];
    allChecked: boolean;


    constructor(private CommonService: CommonService,
                private ToasterService: ToasterService,
                private router: Router, @Inject(PLATFORM_ID) private platform: Object) {
    }

    ngOnInit() {
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        this.all_products_list();
        this.dtOptions = {
            responsive: true
        };

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        if (isPlatformBrowser(this.platform)) {
            $("#productId").select2();
        }
    }


    all_products_list() {
        this.CommonService.allProducts().subscribe(result => {
            this.productRecords = result;
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }


    checkedAll(event) {
        if (event.target.checked) {
            this.selectedAllCheckboxes = [];
            this.allChecked = true;
            for (let singleRecord of this.search_records) {
                this.selectedAllCheckboxes.push(singleRecord.imageId);
            }
        } else {
            this.allChecked = false;
            this.selectedAllCheckboxes = [];
        }
        // this.deleteMultipleImages(this.selectedAllCheckboxes);
    }

    checkedSingle(event, imageId) {
        // console.log(imageId);
        
        let index = this.selectedAllCheckboxes.indexOf(imageId);
        if (event.target.checked) {
            // console.log(index);
            if (index === -1) {
                this.selectedAllCheckboxes.push(imageId);
            } else {
                this.selectedAllCheckboxes.splice(index, 1);
            }
        } else {
            // console.log(index);
            
            this.selectedAllCheckboxes.splice(index, 1);
            // console.log("single checkbox unchecked");
        }
        // this.deleteMultipleImages(this.selectedAllCheckboxes);
        //console.log(this.selectedAllCheckboxes);
    }

    deleteMultipleImages(imageIds){
        this.CommonService.deleteMultipleImages({ids: imageIds.toString()}, 'product/delete_multiple_images/').subscribe(result => {
            // console.log(result);
            
            this.ToasterService.error_toster(
                "Multiple Images deleted successfully"
            );
            // if(result.)
            // console.log(result);
            this.custom_search();
        });
    }

    custom_search() {
        // var product_id = parseInt($('#productId').val());
        // this.CommonService.getdetails(product_id, 'custom_search/get_product/').subscribe(result => {
        //     this.search_records = result;
        //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //         // Destroy the table first
        //         dtInstance.destroy();
        //         // Call the dtTrigger to rerender again
        //         this.dtTrigger.next({
        //             responsive: true
        //         });
        //     });
        // });
        var from_date = $('#from_date').val();
        var to_date = $('#to_date').val();
        // console.log(from_date);
        // console.log(to_date);
        
        

        var arr = ['Manager', 'Marketing'];
        if (arr.indexOf(localStorage.getItem("user_role")) > -1) {
            this.status_access = true;
        } else {
            this.status_access = false;
        }

        this.CommonService.getImagesByDate({from_date:from_date,to_date:to_date}, 'product/get_image_by_date/').subscribe(result => {
            this.response_from_search = result;
            this.search_records = result.results;
            // console.log(result);
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next({
                    responsive: true
                });
            });
        });

    }



    deleteImage(imageId){
        this.CommonService.delete(imageId, 'product/delete_image/').subscribe(result => {
            this.ToasterService.error_toster(
                "Image with "+imageId + " deleted successfully"
            );
            // if(result.)
            // console.log(result);
            this.custom_search();
        });
    }
}
