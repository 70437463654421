import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy
} from "@angular/core";
import {CommonService} from "../../../services/common.service";
import {ToasterService} from "../../../services/toaster.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "app-vieworder",
    templateUrl: "./vieworder.component.html",
    styleUrls: ["./vieworder.component.scss"]
})
export class VieworderComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    url: any;
    order_id: any;
    customer_id: any;
    ordersproductslist: any;
    attachments: any;
    client: any;
    client_name: any;
    client_id: number;
    assign_user: number;
    API_URL = environment.APIBaseURL;
    allDesigners: any = [];

    print_all_qr_code_url: any;
    baseURL = environment.APIBaseURL;

    designer: any;

    sionnah_order_no: any;
    client_order_no: any;
    order_date: any;
    order_remark: any;

    imgurls: string;

    uploadImageOptions = {
        items: 1,
        margin: 0,
        dots: false,
        nav: true,
        navText: [
            '<button type="button" class="btn slider-left-btn"><i class="fa fa-angle-left"></i></button>',
            '<button type="button" class="btn slider-right-btn"><i class="fa fa-angle-right"></i></button>'
        ],
        autoplay: false,
        // loop: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            769: {
                items: 1
            }
        }
    };

    constructor(private route: ActivatedRoute,
                private CommonService: CommonService,
                private ToasterService: ToasterService,
                private formBuilder: FormBuilder,
                private router: Router) {
    }

    ngOnInit() {
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        // this.get_orders_by_customers();
        this.dtOptions = {
            responsive: true
        };

        this.route.queryParams.subscribe(params => {
            this.order_id = params.order_id;
            this.customer_id = params.customer_id;
            this.get_orders_by_customers();
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    get_orders_by_customers() {
        this.CommonService.getdetails(this.customer_id, "customer/").subscribe(
            customer_details => {
                this.client = customer_details[0];
                console.log(this.client);
                this.client_name = this.client.first_name + " " + this.client.last_name;
            }
        );

        this.url = "customer_orders/";
        this.CommonService.orders_by_customers(this.order_id, this.url).subscribe(
            data => {
                console.log(data);
                this.ordersproductslist = data.results;
                this.imgurls = data.attachments;
                this.attachments = data.attachments;

                this.sionnah_order_no = this.ordersproductslist[0].sionnah_order_no;
                this.client_order_no = this.ordersproductslist[0].client_order_no;
                this.order_date = this.ordersproductslist[0].order_date;
                this.order_remark = this.ordersproductslist[0].order_remark;
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
            }
        );
        this.CommonService.getdetails(this.customer_id, "customer/").subscribe(
            customer_details => {
                this.client = customer_details;
            }
        );

        this.CommonService.updateReadStatus(this.order_id, "customer_orders/update_order_status/").subscribe(
            res => {
                console.log(res);
            }
        );
    }

    generate_qr_code_url(products) {
        this.print_all_qr_code_url =
            this.baseURL + "qrcode/print_order_qr_code?id=";
        var params = [];
        products.forEach(function (singleRecord) {
            params.push(singleRecord.order_item_id);
        });
        this.print_all_qr_code_url = this.print_all_qr_code_url + params;
    }
}
