import {Component, OnInit, Input} from '@angular/core';
import {CommonService} from '../services/common.service';
import {ToasterService} from '../services/toaster.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router'
import { Chart} from '../../../node_modules/chart.js'
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


    @Input() public isUserLoggedIn: boolean = true;


    localData: any;

    constructor(private route: ActivatedRoute, private CommonService: CommonService, private ToasterService: ToasterService, private formBuilder: FormBuilder, private router: Router) {
    }

    data: any;
    pendingOrders: any;

    ngOnInit() {

        this.initBarChart();


        // dashboard chart


//       var data3 = [
//         [gd(2019, 1, 1), 80], [gd(2019, 1, 2), 50], [gd(2019, 1, 3), 60], [gd(2019, 1, 4), 70],
//         [gd(2019, 1, 5), 50], [gd(2019, 1, 6), 45], [gd(2019, 1, 7), 80], [gd(2019, 1, 8), 59],
//         [gd(2019, 1, 9), 46], [gd(2019, 1, 10), 87], [gd(2019, 1, 11), 69], [gd(2019, 1, 12), 70],
//         [gd(2019, 1, 13), 50], [gd(2019, 1, 14), 60], [gd(2019, 1, 15), 70], [gd(2019, 1, 16), 76],
//         [gd(2019, 1, 17), 34], [gd(2019, 1, 18), 88], [gd(2019, 1, 19), 88], [gd(2019, 1, 20), 88],
//         [gd(2019, 1, 21), 97], [gd(2019, 1, 22), 44], [gd(2019, 1, 23), 99], [gd(2019, 1, 24), 57],
//         [gd(2019, 1, 25), 78], [gd(2019, 1, 26), 66], [gd(2019, 1, 27), 88], [gd(2019, 1, 28), 90],
//         [gd(2019, 1, 29), 18], [gd(2019, 1, 30), 55], [gd(2019, 1, 31), 93]
//     ];


//     var dataset = [
//         {
//             label: "Number of orders",
//             data: data3,
//             color: "#1ab394",
//             bars: {
//                 show: true,
//                 align: "center",
//                 barWidth: 24 * 60 * 60 * 600,
//                 lineWidth:0
//             }

//         },
//     ];


//     var options = {
//         xaxis: {
//             mode: "time",
//             tickSize: [1, "day"],
//             tickLength: 0,
//             axisLabel: "Date",
//             axisLabelUseCanvas: true,
//             axisLabelFontSizePixels: 10,
//             axisLabelFontFamily: 'Arial',
//             axisLabelPadding: 10,
//             color: "#d5d5d5"
//         },
//         yaxes: [{
//             position: "left",
//             max: 100,
//             color: "#d5d5d5",
//             axisLabelUseCanvas: true,
//             axisLabelFontSizePixels: 12,
//             axisLabelFontFamily: 'Arial',
//             axisLabelPadding: 3
//         }, {
//             position: "right",
//             clolor: "#d5d5d5",
//             axisLabelUseCanvas: true,
//             axisLabelFontSizePixels: 12,
//             axisLabelFontFamily: ' Arial',
//             axisLabelPadding: 67
//         }
//         ],
//         legend: {
//             noColumns: 1,
//             labelBoxBorderColor: "#000000",
//             position: "nw"
//         },
//         grid: {
//             hoverable: false,
//             borderWidth: 0
//         }
//     };

//     function gd(year, month, day) {
//         return new Date(year, month - 1, day).getTime();
//     }

//     var previousPoint = null, previousLabel = null;

//     $.plot($("#flot-dashboard-chart"), dataset, options);


// $("#ionrange_3").ionRangeSlider({
//     min: 0,
//     max: 100,
//     from: 10,
//     step: 5,
//     prettify: true,
//     hasGrid: true,
//     type: 'double'
// });


// $("#ionrange_4").ionRangeSlider({
//   values: [
//       "January", "February", "March",
//       "April", "May", "June",
//       "July", "August", "September",
//       "October", "November", "December"
//   ],
//   prettify: true,
//   type: 'double',
//   hasGrid: true
// });


// $('#data_5 .input-daterange').datepicker({
// keyboardNavigation: false,
// forceParse: false,
// autoclose: true
// });


        //chart end
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(['/login']);
        }
        this.dashboard_count();


    }

    //  onit end
    dashboard_count() {
        this.localData = {
            "user_id": localStorage.getItem("user_id"),
            "user_role": localStorage.getItem("user_role"),
            "first_name": localStorage.getItem("first_name"),
            "last_name": localStorage.getItem("last_name"),
        };
        this.CommonService.dashboard(this.localData.user_id, this.localData.user_role).subscribe(result => {
            this.data = result;
            console.log(this.data);
            this.pendingOrders = this.data.orders;
        });
    }

    dateselected() {
        console.log("in date");
    }

    initBarChart() {
        var barData = {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
                {
                    label: "Completed Orders",
                    backgroundColor: 'rgba(220, 220, 220, 0.5)',
                    pointBorderColor: "#fff",
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: "New Orders",
                    backgroundColor: 'rgba(26,179,148,0.5)',
                    borderColor: "rgba(26,179,148,0.7)",
                    pointBackgroundColor: "rgba(26,179,148,1)",
                    pointBorderColor: "#fff",
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };

        var barOptions = {
            responsive: true
        };


        var canvas = <HTMLCanvasElement> document.getElementById('barChart');
        var ctx2 = canvas.getContext('2d');
        new Chart(ctx2, {type: 'bar', data: barData, options: barOptions});
    }
}
