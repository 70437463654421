import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {


  dtOptions: any = {};
  
  constructor(private CommonService: CommonService , private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    var result = this.CommonService.check_login();
    if( result ==  true ){
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/dashboard']);
    }



    this.dtOptions = {
      responsive: true
    }
  }

}
