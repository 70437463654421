import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";

declare var swal: any;

@Component({
  selector: 'app-clientorderslist',
  templateUrl: './clientorderslist.component.html',
  styleUrls: ['./clientorderslist.component.scss']
})
export class ClientorderslistComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  allordersList: Array<any>;
  customerdeleteurl: string;
  clientname: string;
  client_id: number;
  deleted: any;
  url: string;

  total_pendings:number = 0;
  total_dispatched:number = 0;
  total_completed:number = 0;
  total_cancelled:number = 0;

  constructor(
    private CommonService: CommonService,
    private ToasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit() {
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }

    this.fetchAllordersList();
    this.dtOptions = {
      responsive: true
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchAllordersList() {
    this.CommonService.getallrecords('customer_orders/client_orders/all').subscribe(data => {
      this.allordersList = data;
      var total_pendings = 0;
      var total_cancelled = 0;
      var total_completed = 0;
      var total_dispatched = 0;
  
      this.allordersList.forEach(function (singleRecord) {
          total_pendings = total_pendings + singleRecord.pending_orders;
          total_cancelled = total_cancelled + singleRecord.cancelled_orders;
          total_completed = total_completed + singleRecord.completed_orders;
          total_dispatched = total_dispatched + singleRecord.dispatch_orders;
      });

      this.total_pendings = total_pendings;
      this.total_cancelled = total_cancelled;
      this.total_completed = total_completed;
      this.total_dispatched = total_dispatched;
      
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next({
              responsive: true
          });
      });
    });
  }

}
