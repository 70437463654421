import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CommonComponent } from "./common/common.component";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { HeaderComponent } from "./common/header/header.component";
import { FooterComponent } from "./common/footer/footer.component";
import { OrdersComponent } from "./orders/orders_list/orders.component";
import { OrderFormComponent } from "./orders/order-form/order-form.component";
import { CustomerslistComponent } from "./customer/customerslist/customerslist.component";
import { CustomerformComponent } from "./customer/customerform/customerform.component";
import { AddproductComponent } from "./products/addproduct/addproduct.component";
import { ProductlistComponent } from "./products/productlist/productlist.component";
import { ManagerComponent } from "./manager/manager.component";
import { ManagerListComponent } from "./manager/manager-list/manager-list.component";
import { ManagerformComponent } from "./manager/managerform/managerform.component";
import { UsersComponent } from "./users/users.component";
import { UserslistComponent } from "./users/userslist/userslist.component";
import { UsersformComponent } from "./users/usersform/usersform.component";
import { UserscantaskComponent } from "./userscantask/userscantask.component";
import { ReportsComponent } from "./reports/reports.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { DeleteImageComponent } from "./delete-image/delete-image.component";

import { NgxDropzoneModule } from "ngx-dropzone";
import { OwlModule } from "ngx-owl-carousel";

// data table
import { DataTablesModule } from "angular-datatables";

import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

//file upload
import { FileSelectDirective } from "../../node_modules/ng2-file-upload";

//services
import { ProductService } from "./services/product.service";
import { CommonService } from "./services/common.service";
import { HttpConfigService } from "./services/http-config.service";
import { OrderBy } from "./pipes/orderbypipe";

// reactive form modules
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ManagerlistComponent } from "./users/userslist/managerlist/managerlist.component";
import { DesignerlistComponent } from "./users/userslist/designerlist/designerlist.component";
import { MasterlistComponent } from "./users/userslist/masterlist/masterlist.component";
import { EmbroderylistComponent } from "./users/userslist/embroderylist/embroderylist.component";
import { QualitycheckerlistComponent } from "./users/userslist/qualitycheckerlist/qualitycheckerlist.component";
import { CustomerorderComponent } from "./orders/customerorder/customerorder.component";
import { QuotationComponent } from "./orders/quotation/quotation.component";

// pipe
import { NumberToWordsPipe } from "./pipes/number-to-words.pipe";
import { RoundPipe } from "./pipes/round.pipe";
import { InvoiceComponent } from "./orders/invoice/invoice.component";
import { OrderstatusComponent } from "./customer/orderstatus/orderstatus.component";
import { LableComponent } from "./lable/lable.component";
import { OutsourcelistComponent } from "./users/userslist/outsourcelist/outsourcelist.component";
import { OutsourceComponent } from "./outsource/outsource.component";
import { OutsourceDetailsComponent } from "./outsource-details/outsource-details.component";
import { BulkuploadComponent } from "./bulkupload/bulkupload.component";
import { WholesalecustomerlistComponent } from "./customer/wholesalecustomerlist/wholesalecustomerlist.component";
import { WholesalecustomerformComponent } from "./customer/wholesalecustomerform/wholesalecustomerform.component";
import { UserspecificorderComponent } from "./orders/userspecificorder/userspecificorder.component";
import { DesignertaskComponent } from './users/designertask/designertask.component';
import { VieworderComponent } from './orders/userspecificorder/vieworder/vieworder.component';
import { OrdertrackComponent } from './orders/ordertrack/ordertrack.component';
import { OutsourcevendorComponent } from './users/userslist/outsourcevendor/outsourcevendor.component';
import { MarketingComponent } from './users/userslist/marketing/marketing.component';
import { CancelorderComponent } from './orders/cancelorder/cancelorder.component';
import { MachineEmbroderylistComponent } from "./users/userslist/machineembroderylist/machineembroderylist.component";
import { DispatchlistComponent } from "./users/userslist/dispatchlist/dispatchlist.component";
import { CustomSearchComponent } from './custom-search/custom-search.component';
import { ClientorderslistComponent } from './orders/clientorderslist/clientorderslist.component';
import { AllscantaskComponent } from './allscantask/allscantask.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CommonComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    OrdersComponent,
    OrderFormComponent,
    CustomerslistComponent,
    CustomerformComponent,
    AddproductComponent,
    ProductlistComponent,
    ManagerComponent,
    ManagerListComponent,
    ManagerformComponent,
    UsersComponent,
    UserslistComponent,
    UsersformComponent,
    UserscantaskComponent,
    ReportsComponent,
    InventoryComponent,
    ManagerlistComponent,
    DesignerlistComponent,
    MasterlistComponent,
    EmbroderylistComponent,
    QualitycheckerlistComponent,
    OrderBy,
    CustomerorderComponent,
    FileSelectDirective,
    QuotationComponent,
    NumberToWordsPipe,
    RoundPipe,
    InvoiceComponent,
    OrderstatusComponent,
    LableComponent,
    OutsourcelistComponent,
    OutsourceComponent,
    OutsourceDetailsComponent,
    BulkuploadComponent,
    WholesalecustomerlistComponent,
    WholesalecustomerformComponent,
    UserspecificorderComponent,
    DesignertaskComponent,
    VieworderComponent,
    OrdertrackComponent,
    OutsourcevendorComponent,
    MarketingComponent,
    CancelorderComponent,
    MachineEmbroderylistComponent,
    DispatchlistComponent,
    CustomSearchComponent,
    ClientorderslistComponent,
    AllscantaskComponent,
    DeleteImageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    OwlModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpConfigService,
    ProductService,
    CommonService,
    SweetAlert2Module
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
