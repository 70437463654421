import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { HttpConfigService } from "./http-config.service";
import "rxjs";
import { LoginService } from "./login.service";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  constructor(
    private http: HttpClient,
    private httpConfigService: HttpConfigService,
    private loginService: LoginService
  ) {}

  url: string;

  allProducts() {
    const API_URL = environment.APIBaseURL + "product/all";
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }  

  
  firstAllProducts() {
    const API_URL = environment.APIBaseURL + "product/all/first";
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }  
  
  forSearchProduct() {
    const API_URL = environment.APIBaseURL + "product/for_search_product";
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  get_cust_order_status(url, id) {
    const API_URL = environment.APIBaseURL + url + id;
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  insert(data, url) {
    const API_URL = environment.APIBaseURL + url;

    console.log;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  update(data, url) {
    const API_URL = environment.APIBaseURL + url;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  delete(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  recover(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  /*Print QR Code*/
  // printQRCode(data, url) {
  //     const API_URL = environment.APIBaseURL + url;
  //     return this.http
  //         .post<any>(API_URL, data, this.httpConfigService.getHeaders())
  //         .pipe(map(response => response));
  // }

  getAll(data, url) {
    const API_URL = environment.APIBaseURL + url;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getallrecords(url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  orders_by_customers(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getallrecords_by_role(url, role) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + role;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getdetails(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    console.log(API_URL);
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  dashboard(user_id, user_role) {
    const API_URL =
      `${this.httpConfigService.baseURL}` +
      "dashboard/counts/" +
      user_id +
      "/" +
      user_role;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  uploadImage(data, url) {
    const API_URL = environment.APIBaseURL + url;
    return this.http.post<any>(API_URL, data).pipe(map(response => response));
  }

  tasklist(user_id) {
    const API_URL = environment.APIBaseURL + "order/track/user/" + user_id;
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  check_login() {
    if (localStorage.getItem("token") === null) {
      return true;
    } else {
      return false;
    }
  }

  email_exist(data, url) {
    const API_URL = environment.APIBaseURL + url;
    console.log();
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  deleteProductImages(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  getAllOrdersByCustomerType(url, type) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + "/" + type;
    return this.http
      .get<any>(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  updateReadStatus(id, url) {
    const API_URL = `${this.httpConfigService.baseURL}` + url + id;
    return this.http
        .get<any>(API_URL, this.httpConfigService.getHeaders())
        .pipe(map(response => response));
  }
  
  getImagesByDate(data, url) {
    const API_URL = environment.APIBaseURL + url;
    console.log();
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  } 
  
  deleteMultipleImages(data, url) {
    const API_URL = environment.APIBaseURL + url;
    return this.http
      .post<any>(API_URL, data, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

  
  
  getAllProductCategories() {
    const API_URL = environment.APIBaseURL + "product/get/all_product_categories";
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  } 
  
  GetSpecificCategoryProducts(category) {
    const API_URL = environment.APIBaseURL + "product/get/category/"+category;
    //console.log(API_URL);
    return this.http
      .get(API_URL, this.httpConfigService.getHeaders())
      .pipe(map(response => response));
  }

}
