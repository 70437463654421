import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {CommonService} from "../../../services/common.service";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: "app-designerlist",
  templateUrl: "./designerlist.component.html",
  styleUrls: ["./designerlist.component.scss"]
})
export class DesignerlistComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtTrigger: Subject<any> = new Subject();
  url: string;
  managerlist: Array<any>;
  role: string;
  designerlist: Array<any>;
  designer: string;
  deleteurl: string;

  constructor(private CommonService: CommonService) {
  }

  ngOnInit() {
      this.dtOptions = {
          responsive: true
      };
      this.allDesigner();
  }

  ngAfterViewInit(): void {
      this.dtTrigger.next();
  }

  ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
  }

  allDesigner() {
      this.url = "user/role/";
      this.role = "Designer";
      this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(
          data => {
              this.designerlist = data;
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  // Destroy the table first
                  dtInstance.destroy();
                  // Call the dtTrigger to rerender again
                  this.dtTrigger.next({
                      responsive: true
                  });
              });
          }
      );
  }

  delete_this(designer_id, first_name, last_name) {
      this.designer = first_name + " " + last_name;
      Swal.fire({
          title: "Are you sure?",
          text: "You want to delete " + this.designer,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, Please cancel"
      }).then(result => {
          if (result.value) {
              this.deleteurl = "user/delete/";
              this.designer = first_name + " " + last_name;
              this.CommonService.delete(designer_id, this.deleteurl).subscribe(
                  data => {
                      Swal.fire(
                          "Deleted!",
                          "Your " + this.designer + "has been deleted.",
                          "success"
                      );
                      this.allDesigner();
                  }
              );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                  "Cancelled",
                  "Your " + this.designer + " is safe :)",
                  "error"
              );
          }
      });
  }

  recover(designer_id, first_name, last_name) {
      this.designer = first_name + " " + last_name;
      Swal.fire({
          title: "Are you sure?",
          text: "You want to recover " + this.designer,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, recover it!",
          cancelButtonText: "No, Please cancel"
      }).then(result => {
          if (result.value) {
              this.url = "user/recover/";
              this.designer = first_name + " " + last_name;
              this.CommonService.recover(designer_id, this.url).subscribe(data => {
                  Swal.fire(
                      "Recovered!",
                      "Your " + this.designer + "has been recover.",
                      "success"
                  );
                  this.allDesigner();
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your cancelled process", "error");
          }
      });
  }
}