import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { environment } from "../../../environments/environment";


declare var swal: any;

@Component({
  selector: 'app-orderstatus',
  templateUrl: './orderstatus.component.html',
  styleUrls: ['./orderstatus.component.scss']
})
export class OrderstatusComponent implements AfterViewInit, OnDestroy, OnInit {


  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();


  client : any ;
  customer_id : number;  
  client_name : string;

  userordersList :  any;
  url : string;
  isupdate: boolean = false;


  constructor(private route: ActivatedRoute, private CommonService: CommonService, private ToasterService: ToasterService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {

    
    var result = this.CommonService.check_login();
    if( result ==  true ){
      this.router.navigate(['/login']);
    }



    this.route.queryParams
      .subscribe(params => {
        if (params.action == 'update') {
          this.isupdate = true;
          this.customer_id = params.customer_id;
          this.fetchAllordersList();
        }
      });

    this.dtOptions = {
      responsive: true
    }
  } 

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

customer_details(){
  this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(customer_details => {
    this.client = customer_details;
    this.client_name = this.client[0]['first_name'] +  " " +this.client[0]['last_name'] ;
    
  });
}




fetchAllordersList() {
  this.url = "customer_orders/customer/";
  this.CommonService.get_cust_order_status(this.url, this.customer_id ).subscribe(data => {
    this.userordersList = data;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next({
          responsive: true
      });
    });

  });
}



  

}
