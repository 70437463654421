import {
    Component,
    OnInit,
    PLATFORM_ID,
    Inject,
    ViewChild,
    AfterViewInit,
    OnDestroy
} from "@angular/core";
import {Subject} from "rxjs";
import {CommonService} from "../../services/common.service";
import {ToasterService} from "../../services/toaster.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {BrowserQRCodeReader} from "@zxing/library";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {DataTableDirective} from "angular-datatables";

const codeReader = new BrowserQRCodeReader();
let selectedDeviceId;
declare var $: any;

@Component({
    selector: "app-order-form",
    templateUrl: "./order-form.component.html",
    styleUrls: ["./order-form.component.scss"]
})
export class OrderFormComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    constructor(private route: ActivatedRoute,
                private CommonService: CommonService,
                private ToasterService: ToasterService,
                private formBuilder: FormBuilder,
                private router: Router,
                @Inject(PLATFORM_ID) private platform: Object) {
    }

    imgurl: string = "assets/img/client_profile/default_profile.jpg";
    filetoupload: File = null;
    selectedFile: File;
    allclientList: any;
    url: string;
    clientForm: FormGroup;
    wholesaleclientForm: FormGroup;
    orderForn: FormGroup;
    productForm: FormGroup;
    enableClientForm: boolean = false;
    enableWholeSaleClientForm: boolean = false;
    clientsubmitted = false;
    Wholesalesubmitted = false;
    ordersubmitted = false;
    productsubmitted = false;
    modalClose = false;
    productRecords: any;
    productCategories: any;
    product_id: number;
    product_category_id: number;
    shortlisted = [];
    clientId: any;
    selectedProductSKU: any;
    selectProductId: any;
    selectedSize: any;
    entireRecord: any;
    visible: boolean = false;
    orderSubmitButtonDisable: boolean = false;
    index: number = 0;
    client_details: any;
    first_name: string;
    last_name: string;
    modalscandiv: boolean = false;
    clientNotSelected: boolean = false;
    productNotSelected: boolean = false;
    ProductALreadyExist: boolean = false;

    ProductALreadyExistInModal: boolean = false;
    productNotSelectedModal: boolean = false;

    email_existed: boolean = false;
    wholesale_email_existed: boolean = false;

    //scan detail
    product_scan_details: any;
    client_type: string = "client";

    scanProductDetails: any;

    ngOnInit() {
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        this.dtOptions = {
            responsive: true
        };

        this.Allcustomer_list();
        // this.Allproducts_list();
        this.AllProductCategories();
        if (isPlatformBrowser(this.platform)) {
            (<any>$("#client_list")).select2({
                placeholder: "Select client"
            });
            (<any>$("#productId")).select2({
                placeholder: "Select product",
                minimumInputLength: 4
            });
        }

        this.clientForm = this.formBuilder.group({
            is_active: [""],
            first_name: ["", Validators.required],
            last_name: [""],
            email: ["", [Validators.email]],
            phone: ["", Validators.required],
            profilepic: [""],
            zipcode: [""],
            state: [""],
            city: [""],
            street: [""],
            source: [""],
            gst: [""]
        });

        this.wholesaleclientForm = this.formBuilder.group({
            is_active: [""],
            first_name: ["", Validators.required],
            last_name: [""],
            email: ["", [Validators.email]],
            phone: ["", Validators.required],
            profilepic: [""],
            zipcode: [""],
            state: [""],
            city: [""],
            street: [""],
            source: [""],
            gst: [""]
        });

        this.orderForn = this.formBuilder.group({
            client_list: [""],
            due_date: ["", Validators.required]
        });

        this.productForm = this.formBuilder.group({
            product_id: [""],
            product_category_id: [""],
            product_quantity: ["1", Validators.required],
            product_size: ["38", Validators.required],
            other_item_details: [""]
        });

        codeReader
            .getVideoInputDevices()
            .then(videoInputDevices => {
                /*const sourceSelect = document.getElementById('sourceSelect');*/
                selectedDeviceId = videoInputDevices[0].deviceId;
                if (videoInputDevices.length >= 1) {
                    videoInputDevices.forEach(element => {
                        const sourceOption = document.createElement("option");
                        sourceOption.text = element.label;
                        sourceOption.value = element.deviceId;
                    });
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    enableClient() {
        this.enableClientForm = true;
        this.enableWholeSaleClientForm = false;
    }

    enableWholeSaleClient() {
        this.enableWholeSaleClientForm = true;
        this.enableClientForm = false;
    }

    setClientType() {
        this.client_type = "client";
    }

    setWholeSaleClientType() {
        this.client_type = "wholesale_client";
    }

    get f() {
        return this.clientForm.controls;
    }

    get w() {
        return this.wholesaleclientForm.controls;
    }

    get g() {
        return this.productForm.controls;
    }

    get o() {
        return this.orderForn.controls;
    }

    Allcustomer_list() {
        this.url = "customer/all";
        this.CommonService.getallrecords(this.url).subscribe(data => {
            this.allclientList = data;
        });
    }

    Allproducts_list() {
        this.CommonService.forSearchProduct().subscribe(result => {
            this.productRecords = result;
        });
    }

    

    AllProductCategories() {
        this.CommonService.getAllProductCategories().subscribe(result => {
            this.productCategories = result;
            console.log(this.productCategories);
            
        });
    }

    GetSpecificCategoryProducts(category) {
        this.CommonService.GetSpecificCategoryProducts(category).subscribe(result => {
            this.productRecords = result;
            console.log(this.productRecords);
            
        });
    }

    onClientSubmit() {
        if (this.clientForm.valid) {
            var data = JSON.stringify({
                first_name: this.clientForm.value.first_name,
                last_name: this.clientForm.value.last_name,
                email: this.clientForm.value.email,
                image: this.clientForm.value.profilepic,
                street: this.clientForm.value.street,
                city: this.clientForm.value.city,
                state: this.clientForm.value.state,
                zip: this.clientForm.value.zipcode,
                phone: this.clientForm.value.phone,
                source: this.clientForm.value.source,
                gst: this.clientForm.value.gst,
                client_type: "client",
                is_active: 1
            });

            var email_data = JSON.stringify({
                email: this.clientForm.value.email
            });

            this.CommonService.email_exist(
                email_data,
                "customer/validate_email"
            ).subscribe(result => {
                if (result.status == false || this.clientForm.value.email == "") {
                    this.CommonService.insert(data, "customer/save").subscribe(result => {
                        if (result) {
                            this.clientForm = this.formBuilder.group({
                                is_active: [""],
                                first_name: ["", Validators.required],
                                last_name: [""],
                                email: ["", [Validators.email]],
                                phone: ["", Validators.required],
                                profilepic: [""],
                                zipcode: [""],
                                state: [""],
                                city: [""],
                                street: [""],
                                source: [""],
                                gst: [""]
                            });
                            this.ToasterService.success_toster(
                                this.clientForm.value.first_name + " created successfully"
                            );
                            this.Allcustomer_list();
                            if (isPlatformBrowser(this.platform)) {
                                (<any>$("#cancel-btn")).click();
                            }
                            this.clientsubmitted = false;
                        } else {
                            this.ToasterService.error_toster(
                                " Error while creating a client , Please try again"
                            );
                        }
                    });
                } else {
                    this.email_existed = true;
                }
            });
        } else {
            this.clientsubmitted = true;
            console.log("validation false");
        }
    }

    onWholesaleClientSubmit() {
        if (this.wholesaleclientForm.valid) {
            var data = JSON.stringify({
                first_name: this.wholesaleclientForm.value.first_name,
                last_name: this.wholesaleclientForm.value.last_name,
                email: this.wholesaleclientForm.value.email,
                image: this.wholesaleclientForm.value.profilepic,
                street: this.wholesaleclientForm.value.street,
                city: this.wholesaleclientForm.value.city,
                state: this.wholesaleclientForm.value.state,
                zip: this.wholesaleclientForm.value.zipcode,
                phone: this.wholesaleclientForm.value.phone,
                source: this.wholesaleclientForm.value.source,
                gst: this.wholesaleclientForm.value.gst,
                client_type: "wholesale_client",
                is_active: 1
            });

            var wholesale_email_data = JSON.stringify({
                email: this.wholesaleclientForm.value.email
            });

            this.CommonService.email_exist(
                wholesale_email_data,
                "customer/validate_email"
            ).subscribe(result => {
                if (result.status == false || this.wholesaleclientForm.value.email == "") {
                    this.CommonService.insert(data, "customer/save").subscribe(result => {
                        if (result) {
                            this.wholesaleclientForm = this.formBuilder.group({
                                is_active: [""],
                                first_name: ["", Validators.required],
                                last_name: [""],
                                email: ["", [Validators.email]],
                                phone: ["", Validators.required],
                                profilepic: [""],
                                zipcode: [""],
                                state: [""],
                                city: [""],
                                street: [""],
                                source: [""],
                                gst: [""]
                            });

                            this.ToasterService.success_toster(
                                this.wholesaleclientForm.value.first_name +
                                " created successfully"
                            );
                            this.Allcustomer_list();
                            if (isPlatformBrowser(this.platform)) {
                                (<any>$("#cancel-btn")).click();
                            }
                            this.Wholesalesubmitted = false;
                        } else {
                            this.ToasterService.error_toster(
                                " Error while creating a client , Please try again"
                            );
                        }
                    });
                } else {
                    this.wholesale_email_existed = true;
                }
            });
        } else {
            this.Wholesalesubmitted = true;
            console.log("validation false");
        }
    }

    selectChangeHandler(event: any) {
        this.selectedSize = event.target.value;
        console.log(this.selectedSize);
    }

    handelFileinput(event, file: FileList) {
        this.filetoupload = file.item(0);
        // show image preview
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imgurl = event.target.result;
            console.log(this.imgurl);
        };
        reader.readAsDataURL(this.filetoupload);
        this.selectedFile = event.target.files[0];
        console.log(this.selectedFile);
    }

    addproduct() {
        if (isPlatformBrowser(this.platform)) {
            this.selectProductId = $("#productId").val();
            console.log(this.selectProductId);
        }
        if (this.selectProductId != null && this.selectProductId != "") {
            this.productNotSelected = false;
        } else {
            this.productNotSelected = true;
        }
        if (this.productForm.valid && !this.productNotSelected) {
            var array = this.selectProductId.split(",");
            if (this.shortlisted.some(e => e.product_sku === array[1] && e.product_size === this.productForm.value.product_size)) {
                this.ProductALreadyExist = true;
            } else {
                this.ProductALreadyExist = false;
            }
            if (!this.ProductALreadyExist || this.shortlisted.length == 0) {
                this.shortlisted.push({
                    sr_no: this.index + 1,
                    product_id: array[0],
                    product_sku: array[1],
                    product_name: array[2],
                    product_amt: array[3],
                    product_quantity: this.productForm.value.product_quantity,
                    product_size: this.productForm.value.product_size,
                    other_item_details: this.productForm.value.other_item_details
                });

                this.productForm = this.formBuilder.group({
                    product_id: [""],
                    product_category_id: [""],
                    product_quantity: ["1", Validators.required],
                    product_size: ["38", Validators.required],
                    other_item_details: [""]
                    });

                this.index = this.index + 1;
                // this.productForm.reset();
                this.visible = true;
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
            } else {
                console.log("Product Already There");
            }
        } else {
            console.log("add product validation false");
        }
        this.productsubmitted = true;
        codeReader.reset();
    }

    addmodalproduct(data) {
        if (data) {
            this.modalscandiv = true;
            if (this.shortlisted.some(e => e.product_sku === data.sku && e.product_size === $("#modalsize").val())) {
                this.ProductALreadyExistInModal = true;
            } else {
                this.ProductALreadyExistInModal = false;
            }
            if (!this.ProductALreadyExistInModal || this.shortlisted.length == 0) {
                this.shortlisted.push({
                    sr_no: this.index + 1,
                    product_id: data.product_id,
                    product_sku: data.sku,
                    product_name: data.product_name,
                    product_amt: data.product_amt,
                    product_quantity: $("#modalqty").val(),
                    product_size: $("#modalsize").val(),
                    other_item_details: $("#modalremark").val()
                });

                this.index = this.index + 1;
                // this.productForm.reset();
                this.visible = true;
                this.modalscandiv = false;
                this.scanProductDetails = {};
                console.log(this.scanProductDetails);
                $("#modalproductid").val("");
                codeReader.reset();

                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.dtTrigger.next({
                        responsive: true
                    });
                });
                this.hidemodal();

            } else {
                console.log("Product Already There");
            }

        }
    }

    remove_product(sr_no) {
        this.shortlisted = this.shortlisted.filter(i => i.sr_no !== sr_no);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next({
                responsive: true
            });
        });
    }

    customerOrder() {
        this.orderSubmitButtonDisable = true;
        if (isPlatformBrowser(this.platform)) {
            this.clientId = $("#client_list").val();
            console.log(this.clientId);
        }
        if (this.clientId != null && this.clientId != "") {
            this.clientNotSelected = false;
        } else {
            this.clientNotSelected = true;
        }
        if (this.orderForn.valid && !this.clientNotSelected) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            var current_date = yyyy + "-" + mm + "-" + dd;
            this.entireRecord = {
                order_items: this.shortlisted,
                client_id: this.clientId,
                date_order_placed: current_date,
                date_order_paid: "2019-04-11",
                due_date: this.orderForn.value.due_date,
                user_id: localStorage.getItem("user_id"),
                user_role: localStorage.getItem("user_role"),
            };

            this.CommonService.insert(
                this.entireRecord,
                "customer_orders/save"
            ).subscribe(result => {
                this.CommonService.getdetails(this.clientId, "customer/").subscribe(
                    customer_details => {
                        this.client_details = customer_details;
                        console.log(this.client_details);
                        this.first_name = this.client_details[0]["first_name"];
                        this.last_name = this.client_details[0]["last_name"];
                    }
                );
                this.ToasterService.success_toster("Order Created successfully");
                this.orderSubmitButtonDisable = false;
                this.router.navigate(["/orderslist"]);
            });
        } else {
            this.orderSubmitButtonDisable = false;
            console.log("validation false or client not selected");
        }
        this.ordersubmitted = true;
    }

    scan() {
        this.showmodal();
        codeReader
            .decodeFromInputVideoDevice(selectedDeviceId, "video")
            .then(result => {
                this.modalscandiv = true;
                var data = result["text"];
                this.product_scan_details = JSON.parse(data);
                var data = this.productRecords.filter(
                    obj => obj.product_id == this.product_scan_details.product_id
                );

                this.scanProductDetails = data[0];
                console.log(this.scanProductDetails);
            })
            .catch(err => {
                console.error(err);
                document.getElementById("result").textContent = err;
            });
        console.log(
            `Started continous decode from camera with id ${selectedDeviceId}`
        );
    }

    reset() {
        codeReader.reset();
        this.modalscandiv = false;
        this.scanProductDetails = {};
        console.log(this.scanProductDetails);
        $("#modalproductid").val("");
        this.hidemodal();

    }

    showmodal() {
        $(".modal").addClass("in");
        $(".modal").css('background-color', 'rgba(0,0,0,0.35)');
        $(".modal-backdrop").add();
        $('body').addClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal").show();
    }

    hidemodal() {
        $(".modal").removeClass("in");
        $(".modal").css('background-color', 'transparent');
        $(".modal-backdrop").remove();
        $('body').removeClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal").hide();
    }
}
