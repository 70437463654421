import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { CommonService } from '../services/common.service';
import { ToasterService } from '../services/toaster.service';
import {Router} from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

declare var swal: any;

@Component({
  selector: 'app-outsource-details',
  templateUrl: './outsource-details.component.html',
  styleUrls: ['./outsource-details.component.scss']
})
export class OutsourceDetailsComponent implements  AfterViewInit, OnDestroy, OnInit  {



  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  allordersList: Array<any>;
  customerdeleteurl: string;
  clientname: string;
  client_id: number;
  deleted : any;
  url: string;

  constructor(private CommonService: CommonService, private ToasterService: ToasterService , private router: Router) { }


  ngOnInit() {

    var result = this.CommonService.check_login();
    if( result ==  true ){
      this.router.navigate(['/login']);
    }

    this.fetchAllordersList();
    this.dtOptions = {
      responsive: true
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  fetchAllordersList() {
    this.url = "customer_orders/all";
    this.CommonService.getallrecords(this.url).subscribe(data => {
      this.allordersList = data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next({
            responsive: true
        });
      });

    });
  }

}
