import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor( private CommonService: CommonService, private router: Router) { }

  ngOnInit() {
    var result = this.CommonService.check_login();
    if( result ==  true ){
      this.router.navigate(['/login']);
    }else{
      this.router.navigate(['/dashboard']);
    }


  }


}
