import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: 'app-wholesalecustomerlist',
  templateUrl: './wholesalecustomerlist.component.html',
  styleUrls: ['./wholesalecustomerlist.component.scss']
})
export class WholesalecustomerlistComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  // dtTrigger2: Subject<any> = new Subject();
  allclientList: Array<any>;
  customerdeleteurl: string;
  clientname: string;
  client_id: number;
  deleted: any;
  url: string;

  all_clients: Array<any> = [];
  all_wholesale_clients: Array<any> = [];

  constructor(private CommonService: CommonService,
    private ToasterService: ToasterService,
    private router: Router) { }

  ngOnInit() {
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }
    this.getAllCustomers();
    this.dtOptions = {
      responsive: true
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllCustomers() {
    this.url = "customer/type";
    this.CommonService.getAllOrdersByCustomerType(this.url, 'wholesale_client').subscribe(data => {
      this.all_wholesale_clients = data;
      console.log(this.all_wholesale_clients);
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next({
          responsive: true
        });
      });
    });
    
  }

  delete_this(client_id, first_name, last_name) {
    this.clientname = first_name + " " + last_name;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete " + this.clientname,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, Please cancel"
    }).then(result => {
      if (result.value) {
        this.customerdeleteurl = "customer/delete/";
        this.clientname = first_name + " " + last_name;

        this.CommonService.delete(client_id, this.customerdeleteurl).subscribe(
          data => {
            Swal.fire(
              "Deleted!",
              "Your " + this.clientname + "has been deleted.",
              "success"
            );
            this.getAllCustomers();
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          "Your " + this.clientname + " is safe :)",
          "error"
        );
      }
    });
  }

  recover(client_id, first_name, last_name) {
    this.clientname = first_name + " " + last_name;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to recover " + this.clientname,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, recover it!",
      cancelButtonText: "No, Please cancel"
    }).then(result => {
      if (result.value) {
        this.url = "customer/recover/";
        this.clientname = first_name + " " + last_name;

        this.CommonService.recover(client_id, this.url).subscribe(data => {
          Swal.fire(
            "Recover!",
            "Your " + this.clientname + "has been recover successfully.",
            "success"
          );
          this.getAllCustomers();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "You Cancelled process ", "error");
      }
    });
  }

}
