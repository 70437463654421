import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild, PLATFORM_ID, Inject
} from "@angular/core";
import {CommonService} from "../services/common.service";
import {ToasterService} from "../services/toaster.service";
import {Router} from "@angular/router";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

declare var swal: any;
declare var $;

@Component({
    selector: "app-orders",
    templateUrl: "./custom-search.component.html",
    styleUrls: ["./custom-search.component.scss"]
})
export class CustomSearchComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    client_id: number;
    deleted: any;
    productRecords: any;
    search_records: any;
    url: string;
    user_role = localStorage.getItem("user_role");
    status_access: boolean = false;


    constructor(private CommonService: CommonService,
                private ToasterService: ToasterService,
                private router: Router, @Inject(PLATFORM_ID) private platform: Object) {
    }

    ngOnInit() {
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        this.all_products_list();
        this.dtOptions = {
            responsive: true
        };

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        if (isPlatformBrowser(this.platform)) {
            $("#productId").select2();
        }
    }


    all_products_list() {
        this.CommonService.allProducts().subscribe(result => {
            this.productRecords = result;
        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    custom_search() {
        // var product_id = parseInt($('#productId').val());
        // this.CommonService.getdetails(product_id, 'custom_search/get_product/').subscribe(result => {
        //     this.search_records = result;
        //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //         // Destroy the table first
        //         dtInstance.destroy();
        //         // Call the dtTrigger to rerender again
        //         this.dtTrigger.next({
        //             responsive: true
        //         });
        //     });
        // });
        var product_sku = $('#product_sku').val();

        var arr = ['Manager', 'Marketing'];
        if (arr.indexOf(localStorage.getItem("user_role")) > -1) {
            this.status_access = true;
        } else {
            this.status_access = false;
        }

        this.CommonService.getAll({sku:product_sku}, 'custom_search/get_product_by_sku').subscribe(result => {
            this.search_records = result;
            console.log(result);
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next({
                    responsive: true
                });
            });
        });

    }
}
