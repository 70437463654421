import {Component, OnInit, PLATFORM_ID, Inject} from "@angular/core";
import {CommonService} from "../../services/common.service";
import {ToasterService} from "../../services/toaster.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorHandler} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Component({
    selector: "app-addproduct",
    templateUrl: "./addproduct.component.html",
    styleUrls: ["./addproduct.component.scss"]
})
export class AddproductComponent implements OnInit, ErrorHandler {
    registerForm: FormGroup;
    submitted = false;
    selectedProductType: string = "";
    selectedParentSku: string = "";
    selectedChild: boolean = false;
    //product form fields
    product: any;
    product_id: any;
    id: any;
    productType: number;
    productName: any;
    productImage: any;
    productColor: string;
    parent_sku: any;
    sku: any;
    pr_type: any;
    productCategory: string;
    productAmount: number;
    productDescription: any;
    productCategories = [
        "Gowns",
        "Salwar",
        "Lehengas",
        "Sharara",
        "Skirt & Tops"
    ];
    data: any;
    isupdate: boolean = false;
    is_active: boolean;
    allProductsList: any = [];
    url: any;
    product_name: string;
    order = [];
    filetoupload: File = null;
    imgurls: string;
    month: string;
    selectedFile: File;
    profile_exist: string;
    is_img_uploaded: boolean = false;
    isUploadClick: boolean = false;
    imageData: any = [];
    disableButton: boolean = false;

    /*My Variables*/
    no_of_pieaces;
    category_type;
    selected_category_type;
    product_sku;
    isDesginer:boolean = false;
    wholesale_client_list: any;

    sku_generation: any = [
        {id: 1, name: "Jan", val: "SSJA"},
        {id: 2, name: "Feb", val: "SSFB"},
        {id: 3, name: "Mar", val: "SSMR"},
        {id: 4, name: "Apr", val: "SSAR"},
        {id: 5, name: "May", val: "SSMY"},
        {id: 6, name: "Jun", val: "SSJN"},
        {id: 7, name: "Jul", val: "SSJL"},
        {id: 8, name: "Aug", val: "SSAG"},
        {id: 9, name: "Sep", val: "SSSP"},
        {id: 10, name: "Oct", val: "SSOC"},
        {id: 11, name: "Nov", val: "SSNV"},
        {id: 12, name: "Dec", val: "SSDC"}
    ];

    uploadImageOptions = {
        items: 1,
        margin: 0,
        dots: false,
        nav: true,
        navText: [
            '<button type="button" class="btn slider-left-btn"><i class="fa fa-angle-left"></i></button>',
            '<button type="button" class="btn slider-right-btn"><i class="fa fa-angle-right"></i></button>'
        ],
        autoplay: false,
        // loop: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            769: {
                items: 1
            }
        }
    };

    allDesigners: any = [];
    currentDeleteImage : number;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private CommonService: CommonService,
                private ToasterService: ToasterService,
                private formBuilder: FormBuilder,
                public http: HttpClient,
                @Inject(PLATFORM_ID) private platform: Object) {
    }

    handleError(error) {
        // your custom error handling logic
        console.log(error);
    }

    ngOnInit() {
        this.set_no_of_pieaces();
        this.set_category_type();
        this.set_wholesale_client_list();
        this.allDesigner();
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        this.registerForm = this.formBuilder.group({
            productName: ["", Validators.required],
            pr_type: ["", Validators.required],
            product_clr: ["", [Validators.required, Validators.minLength(3)]],
            productDescription: [""],
            product_amt: ["", Validators.required],
            wsp: ["", Validators.required],
            fop: ["", Validators.required],
            productCategory: ["", Validators.required],
            product_sku: [""],
            parent_sku: [""],
            // productImage: [''],
            pieces: [""]
        });

        this.route.queryParams.subscribe(params => {
            if (params.action == "update") {
                this.update_product_info(params.product_id);
            } else {
                if (isPlatformBrowser(this.platform)) {
                    $("#pr_type").removeAttr("disabled");
                    $("#productCategory").removeAttr("disabled");
                }
            }
        });

        this.Allproducts_list();
        if (isPlatformBrowser(this.platform)) {
            (<any>$(".select2_dropdown")).select2();
        }
    }

    allDesigner() {
        this.url = "user/role/";
        this.CommonService.getallrecords_by_role(this.url, "Designer").subscribe(
            data => {
                this.allDesigners = data;
                // console.log(this.allDesigners);
            }
        );
    }

    update_product_info(product_id) {
        if(localStorage.getItem("user_role") == 'Designer') {
            this.isDesginer = true;
        }
        this.isupdate = true;
        if (isPlatformBrowser(this.platform)) {
            $("#pr_type").attr("disabled", "true");
            $("#productCategory").attr("disabled", "true");
        }
        this.product_id = product_id;
        this.getRecord();
    }

    getRecord() {
        this.CommonService.getdetails(this.product_id, "product/").subscribe(
            product_details => {
                console.log(product_details);
                this.product = product_details;
                this.product_id = this.product[0]["product_id"];
                this.productName = this.product[0]["product_name"];
                this.parent_sku = this.product[0]["parent_sku"];
                this.pr_type = this.product[0]["parent"];
                if (this.pr_type == "0") {
                    this.registerForm.controls["product_sku"].enable();
                } else {
                    this.registerForm.controls["product_sku"].disable();
                }
                this.product_sku = this.product[0]["sku"];
                if (this.product[0]["product_images"]) {
                    this.imgurls = this.product[0]["product_images"];
                    // console.log(this.imgurls);
                }

                if (this.product[0]["id"] == this.product_sku) {
                    var d = new Date();
                    var year = d.getFullYear();
                    console.log(year);
                    var mm = d.getMonth() + 1;
                    var num: number = mm;
                    if (this.product[0]["wsp"] < 10000) {
                        this.id = "SSL0" + this.product[0]["wsp"] + year;
                    } else {
                        this.id = "SSL" + this.product[0]["wsp"] + year;
                    }
                } else {
                    this.id = this.product[0]["id"];
                }

                // if (this.pr_type < 1) {
                //   this.selectedChild = true;
                // } else {
                //   this.selectedChild = false;
                // }

                this.registerForm = this.formBuilder.group({
                    productName: [this.product[0]["product_name"], Validators.required],
                    pr_type: [this.product[0]["parent"], Validators.required],
                    product_clr: [this.product[0]["product_color"], Validators.required],
                    productDescription: [this.product[0]["product_description"]],
                    product_amt: [this.product[0]["product_amt"], Validators.required],
                    wsp: [this.product[0]["wsp"], Validators.required],
                    fop: [this.product[0]["fop"], Validators.required],
                    productCategory: [
                        this.product[0]["productCategory"],
                        Validators.required
                    ],
                    // sku: [this.product[0]["sku"], Validators.required],
                    parent_sku: [this.product[0]["parent_sku"]],
                    product_sku: [this.product[0]["sku"]],
                    pieces: [this.product[0]["pieces"], Validators.required]
                    // productImage: [this.product[0]['qr_img']],
                });
            }
        );
    }

    handelFileinput(event, file: FileList) {
        this.filetoupload = file.item(0);
        // show image preview
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imgurls = event.target.result;
        };
        reader.readAsDataURL(this.filetoupload);
        this.selectedFile = event.target.files[0];
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.disableButton = true;
        if (this.registerForm.valid) {
            var d = new Date();
            var year = d.getFullYear();
            console.log(year);
            var mm = d.getMonth() + 1;
            var num: number = mm;

            if (this.registerForm.value.wsp < 10000) {
                var product_id = "SSL0" + this.registerForm.value.wsp + year;
            } else {
                var product_id = "SSL" + this.registerForm.value.wsp + year;
            }

            switch (num) {
                case 1: {
                    this.month = "JAN";
                    break;
                }
                case 2: {
                    this.month = "FEB";
                    break;
                }
                case 3: {
                    this.month = "MAR";
                    break;
                }
                case 4: {
                    this.month = "APR";
                    break;
                }
                case 5: {
                    this.month = "MAY";
                    break;
                }
                case 6: {
                    this.month = "JUN";
                    break;
                }
                case 7: {
                    this.month = "JUL";
                    break;
                }
                case 8: {
                    this.month = "AUG";
                    break;
                }
                case 9: {
                    this.month = "SEP";
                    break;
                }

                case 10: {
                    this.month = "OCT";
                    break;
                }

                case 11: {
                    this.month = "NOV";
                    break;
                }

                case 12: {
                    this.month = "DEC";
                    break;
                }

                default: {
                    console.log("JAAN");
                    break;
                }
            }

            if (!this.selectedChild && this.registerForm.value.productCategory !== 'KL' && this.registerForm.value.productCategory !== 'CL') {
                this.product_sku = $("#product_sku").val();
            } else {
                this.product_sku = this.registerForm.value.product_sku;
            }
            var order = {
                id: product_id,
                product_name: this.registerForm.value.productName,
                product_description: this.registerForm.value.productDescription,
                product_color: this.registerForm.value.product_clr,
                productCategory: this.registerForm.value.productCategory,
                product_amt: this.registerForm.value.product_amt,
                wsp: this.registerForm.value.wsp,
                fop: this.registerForm.value.fop,
                sku: this.product_sku,
                parent: this.registerForm.value.pr_type,
                pieces: this.registerForm.value.pieces,
                is_deleted: 0
            };

            if (this.selectedChild == true) {
                order["parent_sku"] = this.registerForm.value.parent_sku;
            }

            const uploadData = new FormData();
            if (this.imageData) {
                // console.log(this.imageData.length);
                for (let i = 0; i < this.imageData.length; i++) {
                    uploadData.append("image", this.imageData[i]);
                }
            }
            this.CommonService.insert(order, "product/save").subscribe(result => {
                if (result) {
                    if (this.selected_category_type) {
                        if (!this.selectedChild) {
                            this.http
                                .post(
                                    environment.APIBaseURL + "default/edit_values",
                                    {category_code: this.selected_category_type},
                                    {responseType: "json"}
                                )
                                .subscribe((data: Array<any>) => {
                                });
                        }
                    }
                    if (this.imageData.length > 0) {
                        console.log('image upload here');
                        uploadData.append("product_id", result.insertId);
                        this.CommonService.uploadImage(
                            uploadData,
                            "product/upload_image"
                        ).subscribe(result => {
                            // console.log(result);
                        });
                    }

                    this.ToasterService.success_toster(
                        this.registerForm.value.productName + " created successfully"
                    );
                    this.router.navigate(["/productlist"]);
                } else {
                    this.ToasterService.error_toster(
                        " Error while creating a product , Please try again"
                    );
                }
            });
            this.disableButton = false;
        } else {
            console.log("validation false");
            this.disableButton = false;
        }
        this.submitted = true;
    }

    selectChangeHandler(event: any) {
        this.selectedProductType = event.target.value;
        if (this.selectedProductType == "0") {
            this.selectedChild = true;
            // product is child
            this.productType = 0;
            this.registerForm.controls["product_sku"].enable();
        } else {
            this.selectedChild = false;
            //product is parent
            this.productType = 1;
            this.parent_sku = "";
            this.registerForm.controls["product_sku"].disable();
        }

        if(this.selected_category_type == 'CL' || this.selected_category_type == 'KL') {
            this.registerForm.controls["product_sku"].enable();
        }
    }

    selectParentSku(event: any) {
        this.product_sku = event.target.value;
    }

    updateCustomer(product_id) {
        this.disableButton = true;
        if (this.registerForm.value.wsp != this.product[0]["wsp"]) {
            var d = new Date();
            var year = d.getFullYear();
            var mm = d.getMonth() + 1;
            var num: number = mm;
            if (this.registerForm.value.wsp < 10000) {
                this.id = "SSL0" + this.registerForm.value.wsp + year;
            } else {
                this.id = "SSL" + this.registerForm.value.wsp + year;
            }
        }

        this.product_sku = $("#product_sku").val();

        if (this.registerForm.valid) {
            var data = {
                id: this.id,
                product_name: this.registerForm.value.productName,
                product_description: this.registerForm.value.productDescription,
                product_color: this.registerForm.value.product_clr,
                product_amt: this.registerForm.value.product_amt,
                wsp: this.registerForm.value.wsp,
                fop: this.registerForm.value.fop,
                productCategory: this.registerForm.value.productCategory,
                // 'product_image': this.registerForm.value.productImage,
                sku: this.product_sku,
                parent: this.registerForm.value.pr_type,
                // 'parent_sku': this.registerForm.value.parent_sku,
                pieces: this.registerForm.value.pieces
            };
            if (this.registerForm.value.pr_type == 0) {
                data["parent_sku"] = this.registerForm.value.parent_sku;
            } else {
                data["parent_sku"] = "";
            }
            const uploadData = new FormData();
            if (this.imageData) {
                // console.log(this.imageData.length);
                for (let i = 0; i < this.imageData.length; i++) {
                    uploadData.append("image", this.imageData[i]);
                }
            }
            this.CommonService.update(data, "product/update/" + product_id).subscribe(
                result => {
                    // console.log(result);
                    if (result) {
                        if (this.imageData.length > 0) {
                            uploadData.append("product_id", product_id);
                            this.CommonService.uploadImage(
                                uploadData,
                                "product/upload_image"
                            ).subscribe(result => {
                            });
                        }
                        this.ToasterService.success_toster(
                            this.registerForm.value.productName + " Updated successfully"
                        );
                        this.router.navigate(["/productlist"]);
                    } else {
                        this.ToasterService.error_toster(
                            " Error while Updating a product details , Please try again"
                        );
                    }
                }
            );
        this.disableButton = false;
        } else {
            this.disableButton = false;
            console.log("validation false");
        }
        this.submitted = true;
    }

    enablefileUpload() {
        this.isUploadClick = true;
    }

    //ngx dropzon
    onFilesAdded(files: File[]) {
        // console.log(files);
        this.imageData = files;
        // console.log(this.imageData);

        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = (e: ProgressEvent) => {
                const content = (e.target as FileReader).result;

                // this content string could be used directly as an image source
                // or be uploaded to a webserver via HTTP request.
                // console.log(content);
            };

            // use this for basic text files like .txt or .csv
            //reader.readAsText(file);

            // use this for images
            reader.readAsDataURL(file);
        });
    }

    deleteImage(index) {
        this.imageData.splice(parseInt(index), 1);
    }

    Allproducts_list() {
        this.url = "product/all";
        this.CommonService.getallrecords(this.url).subscribe(data => {
            var results = [];
            data.forEach(function (singleRecord) {
                if (singleRecord.parent == "1") {
                    results.push(singleRecord);
                }
            });
            // console.log(results);
            this.allProductsList = results;
        });
    }

    set_no_of_pieaces() {
        this.no_of_pieaces = [
            {
                id: 1,
                value: "1 piece"
            },
            {
                id: 2,
                value: "2 piece"
            },
            {
                id: 3,
                value: "3 piece"
            },
            {
                id: 4,
                value: "4 piece"
            },
            {
                id: 5,
                value: "5 piece"
            }
        ];
    }

    set_category_type() {
        this.category_type = [
            {
                id: 1,
                category_name: "Saree",
                category_code: "SR"
            },
            {
                id: 2,
                category_name: "Gown",
                category_code: "GOW"
            },
            {
                id: 3,
                category_name: "Sks",
                category_code: "SKS"
            },
            {
                id: 4,
                category_name: "Skirt",
                category_code: "SKT"
            },
            {
                id: 5,
                category_name: "Lehenga",
                category_code: "LH"
            },
            {
                id: 6,
                category_name: "Tunic",
                category_code: "KU"
            },
            {
                id: 7,
                category_name: "Client",
                category_code: "CL"
            },
            {
                id: 8,
                category_name: "Wholesale Client",
                category_code: "KL"
            },
            {
                id: 9,
                category_name: "Cotton Kurti",
                category_code: "CKU"
            }
        ];
    }

    set_wholesale_client_list() {
        this.url = "customer/type";
        this.CommonService.getAllOrdersByCustomerType(this.url, 'wholesale_client').subscribe(data => {
            this.wholesale_client_list = data;
            // console.log(this.wholesale_client_list);
        });
    }

    get_wholesale_client_sku(val) {
        this.product_sku = val;
    }

    set_product_sku(val) {
        this.productCategory = val;
        if(val !== 'KL') {
            this.registerForm.controls["product_sku"].disable();
            if (!this.selectedChild) {
                this.http
                    .get(environment.APIBaseURL + "default/get_all_values", {
                        responseType: "json"
                    })
                    .subscribe((data: Array<any>) => {
                        // console.log(data);
                        var res = data.filter(function (obj) {
                            if (obj.category_code == val) {
                                return obj;
                            }
                        });
                        var d = new Date();
                        var mm = d.getMonth() + 1;
                        var month_code = this.sku_generation.filter(obj => obj.id == mm);
                        if(val == 'CL') {
                            this.product_sku = month_code[0].val + "-" + val;
                            this.registerForm.controls["product_sku"].enable();
                        }
                        else {
                            this.product_sku = month_code[0].val + "-" + val + "-" + res[0].value;
                        }

                        this.selected_category_type = val;
                    });
            }
        }
        else {
            this.registerForm.controls["product_sku"].enable();
            // console.log(this.productCategory);
        }
    }

    deleteProductImage(imageId, product_id) {
        console.log("delete click");
        this.CommonService.deleteProductImages(
            imageId,
            "product/delete_image/"
        ).subscribe(data => {
            // console.log(data);
            this.update_product_info(product_id);
        });
    }
}