import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-outsourcelist',
  templateUrl: './outsourcelist.component.html',
  styleUrls: ['./outsourcelist.component.scss']
})
export class OutsourcelistComponent implements AfterViewInit, OnDestroy, OnInit{



  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2 : any = {};
  dtTrigger: Subject<any> = new Subject();
  url: string;
  outsorcedlist: Array<any>;
  role :  string;
  deleteurl: string;
  mastername: string;
  master_id: number;
  deleted : any;

  constructor(private CommonService: CommonService) { }

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    }
    this.alloutsourced();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  alloutsourced(){
    this.url = "user/role/";
    this.role = "OutSource";
    this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(data => {
      this.outsorcedlist = data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next({
            responsive: true
        });
      });
    });
  }

  delete_this(master_id,first_name,last_name) {
    this.mastername = first_name + " " + last_name;
    Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete ' + this.mastername,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, Please cancel'
    }).then((result) => {
        if (result.value) {
            this.deleteurl = 'user/delete/';
            this.mastername = first_name + " " + last_name;
            this.CommonService.delete(master_id, this.deleteurl).subscribe(data => {
                Swal.fire(
                    'Deleted!',
                    'Your ' + this.mastername + ' has been deleted.',
                    'success'
                )
                this.alloutsourced();
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'Your '+ this.mastername+' is safe :)',
                'error'
            )
        }
    });
  }


     

  
  recover(master_id,first_name,last_name) {
    this.mastername = first_name + " " + last_name;
    Swal.fire({
        title: 'Are you sure?',
        text: 'You want to recover ' + this.mastername,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, recover it!',
        cancelButtonText: 'No, Please cancel'
    }).then((result) => {
        if (result.value) {
            this.url = 'user/recover/';
            this.mastername = first_name + " " + last_name;
            this.CommonService.recover(master_id, this.url).subscribe(data => {
                Swal.fire(
                    'Recovered !',
                    'Your ' + this.mastername +'has been recovered.',
                    'success'
                )
                this.alloutsourced();
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'You cancelled process',
                'error'
            )
        }
    });
  }


}
