import {Component, OnInit, ɵConsole, PLATFORM_ID,Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../services/common.service';
import {ToasterService} from '../../services/toaster.service';
import {finalize} from 'rxjs/operators';
import {AlertPromise} from 'selenium-webdriver';
import {PercentPipe} from '@angular/common';
import { RoundPipe } from '../../pipes/round.pipe';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare var $ : any;

@Component({
    selector: 'app-quotation',
    templateUrl: './quotation.component.html',
    styleUrls: ['./quotation.component.scss'],
    providers: [RoundPipe]
})
export class QuotationComponent implements OnInit {

    constructor(private formBuilder: FormBuilder, private RoundPipe: RoundPipe, private route: ActivatedRoute, private CommonService: CommonService, private ToasterService: ToasterService, @Inject(PLATFORM_ID) private platform: Object) {
    }

    disc_value: number;
    price: number;
    percent_discount: number;
    discountForm: FormGroup;
    value: any;
    url: any;
    order_id: any;
    customer_id: any;
    client: string;
    client_name: string;
    ordersproductslist: any;
    contentEditable: any;
    total: number;
    finaliztotal: number;
    cgst_value: number;
    sgst_value: number;
    final_total: number;
    payable_amount: number;
    tax_amount: number;
    cgst_visible: boolean = false;
    sgst_visible: boolean = false;
    discounted_percentage: any;
    discounted_value: any;
    product_value: any;
    product_price1: any = 6950;
    product_price2: any = 8200;
    percent: any;
    final_price: any = 0;
    final_price1: any;
    sr_no1: number = 1;
    sr_no2: number = 2;
    product_final_value: any;
    discounted_price: any;

    ngOnInit() {
        this.route.queryParams
            .subscribe(params => {
                if (params.action == 'update') {
                    // this.isupdate = true;
                    this.order_id = params.order_id;
                    this.customer_id = params.customer_id;
                    this.get_orders_by_customers();
                }
            });

    }

    get_orders_by_customers() {
        this.CommonService.getdetails(this.customer_id, 'customer/').subscribe(customer_details => {
            this.client = customer_details[0];
        });
        this.url = "customer_orders/";
        this.CommonService.orders_by_customers(this.order_id, this.url).subscribe(data => {
            this.ordersproductslist = data;
        });
    }


    calculate_discount(searchValue: string, product_price, class_name, quantity) {
        var discounte_class = "discounted_price" + class_name;
        var product_final_value = "product_final_value" + class_name;
        this.product_value = product_price;
        this.percent = searchValue;
        this.discounted_value = (this.product_value * this.percent) / 100;
        this.final_price = this.RoundPipe.transform(this.product_value - this.discounted_value);
        this.product_final_value = this.final_price * quantity;
        document.getElementsByClassName(discounte_class)[0].innerHTML = this.final_price;
        document.getElementsByClassName(product_final_value)[0].innerHTML = this.product_final_value;
        var sum = 0.0;
        if (isPlatformBrowser(this.platform)) {
            $('.pfv').each(function () {
                sum += parseFloat($(this).text());
            });
            this.total = sum;
        }
    }


    tax(event: any) {
        if (isPlatformBrowser(this.platform)) {
            this.total = Number((<any>$('#total')).val());
        }
        this.tax_amount = Number(((this.total / 100) * 6).toFixed(0));

        if (this.payable_amount == undefined || this.payable_amount == NaN) {
            this.payable_amount = this.total;
        }
        if (event == "applycgst") {
            this.payable_amount = Number((this.payable_amount + this.tax_amount).toFixed(0));
            this.cgst_visible = true;
        }
        else if (event == "reomvecsgt") {
            this.payable_amount = Number((this.payable_amount - this.tax_amount).toFixed(0));
            this.cgst_visible = false;
        }
        if (event == "applysgst") {
            this.payable_amount = Number((this.payable_amount + this.tax_amount).toFixed(0));
            this.sgst_visible = true;
        }
        else if (event == "reomvesgt") {
            this.payable_amount = Number((this.payable_amount - this.tax_amount).toFixed(0));
            this.sgst_visible = false;
        }
    }

}
