import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-wholesalecustomerform",
  templateUrl: "./wholesalecustomerform.component.html",
  styleUrls: ["./wholesalecustomerform.component.scss"]
})
export class WholesalecustomerformComponent implements OnInit {
  imgurl: string = "assets/img/client_profile/default_profile.jpg";
  filetoupload: File = null;
  clientForm: FormGroup;
  wholesaleclientForm: FormGroup;
  submitted = false;
  // CUSTOMER FORM FILEDS
  first_name: string;
  last_name: string;
  email: string;
  phone: number;
  data: any;
  client_id: number;
  profile_pic: any;
  updatebtn: boolean = false;
  client: Array<any>;
  city: Array<any>;
  state: Array<any>;
  street: string;
  zip: number;
  source: string;
  isupdate: boolean = false;
  selectedFile: File;
  email_existed: boolean = false;

  // clientDetailsForm: boolean = true;
  // wholeSaleClientDetailsForm: boolean = false;
  client_type: any = "wholesale_client";

  constructor(
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private ToasterService: ToasterService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }

    this.clientForm = this.formBuilder.group({
      is_active: [""],
      first_name: ["", Validators.required],
      last_name: [""],
      email: ["", [Validators.email]],
      phone: ["", [Validators.required]],
      profilepic: [""],
      zipcode: [""],
      state: [""],
      city: [""],
      street: [""],
      source: [""],
      agent: [""],
      agent_phone: [""],
      gst: [""],
      officePhone: [""],
      code: [""]
    });

    this.route.queryParams.subscribe(params => {
      if (params.action == "update") {
        this.isupdate = true;
        this.client_id = params.client_id;
        this.getRecord();
      }
    });
  }

  onFileChanged(event) {
    alert("in customer form onchangefile");
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
  }

  getRecord() {
    this.CommonService.getdetails(this.client_id, "customer/").subscribe(
      customer_details => {
        this.client = customer_details;
        this.client_id = this.client[0]["id"];
        this.first_name = this.client[0]["first_name"];
        this.last_name = this.client[0]["last_name"];
        if (this.client[0]["avtar_url"]) {
          this.imgurl = this.client[0]["avtar_url"];
        }
        this.clientForm = this.formBuilder.group({
          first_name: [this.client[0]["first_name"], Validators.required],
          last_name: [this.client[0]["last_name"]],
          email: [this.client[0]["email"], [Validators.email]],
          phone: [this.client[0]["phone"], [Validators.required]],
          // profilepic: [''],
          zipcode: [this.client[0]["zip"]],
          state: [this.client[0]["state"]],
          city: [this.client[0]["city"]],
          street: [this.client[0]["street"]],
          source: [this.client[0]["source"]],
          agent: [this.client[0]["agent"]],
          agent_phone: [this.client[0]["agent_phone"]],
          gst: [this.client[0]["GST"]],
          officePhone: [this.client[0]["officePhone"]],
          is_active: [this.client[0]["is_active"]],
          code: [this.client[0]["code"]]
        });
      }
    );
  }

  handelFileinput(event, file: FileList) {
    this.filetoupload = file.item(0);
    // show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgurl = event.target.result;
    };
    reader.readAsDataURL(this.filetoupload);
    this.selectedFile = event.target.files[0];
  }

  get f() {
    return this.clientForm.controls;
  }

  onSubmit() {
    console.log(this.clientForm.valid);
    if (this.clientForm.valid) {
      var data = JSON.stringify({
        first_name: this.clientForm.value.first_name,
        last_name: this.clientForm.value.last_name,
        client_type: this.client_type,
        email: this.clientForm.value.email,
        image: this.clientForm.value.profilepic,
        street: this.clientForm.value.street,
        city: this.clientForm.value.city,
        state: this.clientForm.value.state,
        zip: this.clientForm.value.zipcode,
        phone: this.clientForm.value.phone,
        source: this.clientForm.value.source,
        agent: this.clientForm.value.agent,
        agent_phone: this.clientForm.value.agent_phone,
        officePhone: this.clientForm.value.officePhone,
        gst: this.clientForm.value.gst,
        code: this.clientForm.value.code,
        is_active: 1
      });

      var email_data = JSON.stringify({
        email: this.clientForm.value.email
      });

      const uploadData = new FormData();
      if (this.selectedFile) {
        uploadData.append("image", this.selectedFile, this.selectedFile.name);
      }

      this.CommonService.email_exist(
        email_data,
        "customer/validate_email"
      ).subscribe(result => {
        if (result.status == false || this.clientForm.value.email == "") {
          this.CommonService.insert(data, "customer/save").subscribe(result => {
            if (result) {
              if (this.selectedFile) {
                uploadData.append("client_id", result.insertId);
                this.CommonService.uploadImage(
                  uploadData,
                  "customer/upload_image"
                ).subscribe(result => {});
              }

              this.ToasterService.success_toster(
                this.clientForm.value.first_name + " created successfully"
              );
              this.router.navigate(["/wholesalecustomerlist"]);
            } else {
              this.ToasterService.error_toster(
                " Error while creating wholesale a client , Please try again"
              );
            }
          });
        } else {
          this.email_existed = true;
        }
      });
    } else {
      console.log("validation false");
    }
    this.submitted = true;
  }

  updateCustomer(client_id) {
    if (this.clientForm.valid) {
      this.updateProduct(client_id);
    } else {
      console.log("validation false");
    }
    this.submitted = true;
  }

  updateProduct(client_id) {
    var data = JSON.stringify({
      first_name: this.clientForm.value.first_name,
      last_name: this.clientForm.value.last_name,
      email: this.clientForm.value.email,
      image: this.clientForm.value.profilepic,
      street: this.clientForm.value.street,
      city: this.clientForm.value.city,
      state: this.clientForm.value.state,
      zip: this.clientForm.value.zipcode,
      phone: this.clientForm.value.phone,
      source: this.clientForm.value.source,
      agent: this.clientForm.value.agent,
      agent_phone: this.clientForm.value.agent_phone,
      officePhone: this.clientForm.value.officePhone,
      gst: this.clientForm.value.gst,
      code: this.clientForm.value.code,
      is_active: this.clientForm.value.is_active
    });

    const uploadData = new FormData();

    if (this.selectedFile) {
      uploadData.append("image", this.selectedFile, this.selectedFile.name);
    }

    var email_data = JSON.stringify({
      email: this.clientForm.value.email
    });

    this.CommonService.email_exist(
      email_data,
      "customer/validate_email"
    ).subscribe(result => {
      if (
        result.status == false ||
        this.client[0]["email"] == this.clientForm.value.email
      ) {
        this.CommonService.update(
          data,
          "customer/update/" + client_id
        ).subscribe(result => {
          if (result) {
            if (this.selectedFile) {
              uploadData.append("client_id", client_id);
              this.CommonService.uploadImage(
                uploadData,
                "customer/upload_image"
              ).subscribe(result => {});
            }

            this.ToasterService.success_toster(
              this.clientForm.value.first_name + " Updated successfully"
            );
            this.router.navigate(["/wholesalecustomerlist"]);
          } else {
            this.ToasterService.error_toster(
              " Error while Updating a wholesale client details , Please try again"
            );
          }
        });
      } else {
        this.email_existed = true;
      }
    });
  }
}
