import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { OrdersComponent } from "./orders/orders_list/orders.component";
import { OrderFormComponent } from "./orders/order-form/order-form.component";
import { CustomerslistComponent } from "./customer/customerslist/customerslist.component";
import { CustomerformComponent } from "./customer/customerform/customerform.component";
import { AddproductComponent } from "./products/addproduct/addproduct.component";
import { ProductlistComponent } from "./products/productlist/productlist.component";
import { UserslistComponent } from "./users/userslist/userslist.component";
import { UsersformComponent } from "./users/usersform/usersform.component";
import { UserscantaskComponent } from "./userscantask/userscantask.component";
import { ReportsComponent } from "./reports/reports.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { CustomerorderComponent } from "./orders/customerorder/customerorder.component";
import { QuotationComponent } from "./orders/quotation/quotation.component";
import { InvoiceComponent } from "./orders/invoice/invoice.component";
import { OrderstatusComponent } from "./customer/orderstatus/orderstatus.component";
import { LableComponent } from "./lable/lable.component";
import { OutsourceComponent } from "./outsource/outsource.component";
import { OutsourceDetailsComponent } from "./outsource-details/outsource-details.component";
import { BulkuploadComponent } from "./bulkupload/bulkupload.component";
import { WholesalecustomerlistComponent } from "./customer/wholesalecustomerlist/wholesalecustomerlist.component";
import { WholesalecustomerformComponent } from "./customer/wholesalecustomerform/wholesalecustomerform.component";
import { UserspecificorderComponent } from "./orders/userspecificorder/userspecificorder.component";
import { DesignertaskComponent } from "./users/designertask/designertask.component";
import { OutsourcevendorComponent } from "./users/userslist/outsourcevendor/outsourcevendor.component";
import { MarketingComponent } from "./users/userslist/marketing/marketing.component";
import { VieworderComponent } from "./orders/userspecificorder/vieworder/vieworder.component";
import { OrdertrackComponent } from "./orders/ordertrack/ordertrack.component";
import { CancelorderComponent } from "./orders/cancelorder/cancelorder.component";
import { MachineEmbroderylistComponent } from "./users/userslist/machineembroderylist/machineembroderylist.component";
import { DispatchlistComponent } from "./users/userslist/dispatchlist/dispatchlist.component";
import { CustomSearchComponent } from "./custom-search/custom-search.component";
import { ClientorderslistComponent } from './orders/clientorderslist/clientorderslist.component';
import { AllscantaskComponent } from './allscantask/allscantask.component';
import { DeleteImageComponent } from './delete-image/delete-image.component';


const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "app", component: AppComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "login", component: LoginComponent },
  { path: "orderslist", component: OrdersComponent },
  { path: "clientorderslist", component: ClientorderslistComponent },
  { path: "order", component: OrderFormComponent },
  { path: "customerlist", component: CustomerslistComponent },
  { path: "wholesalecustomerlist", component: WholesalecustomerlistComponent },
  // {path: 'customerform/:id', component: CustomerformComponent},
  { path: "addproduct", component: AddproductComponent },
  { path: "productlist", component: ProductlistComponent },
  { path: "customer-form", component: CustomerformComponent },
  { path: "wholesalecustomerform", component: WholesalecustomerformComponent },
  { path: "users", component: UserslistComponent },
  { path: "createusers", component: UsersformComponent },
  { path: "scantask", component: UserscantaskComponent },
  { path: "reports", component: ReportsComponent },
  { path: "inventory", component: InventoryComponent },
  { path: "customerorders", component: CustomerorderComponent },
  { path: "quotation", component: QuotationComponent },
  { path: "invoice", component: InvoiceComponent },
  { path: "orderstatus", component: OrderstatusComponent },
  { path: "lable", component: LableComponent },
  { path: "outsource", component: OutsourceComponent },
  { path: "OutsourceDetails", component: OutsourceDetailsComponent },
  { path: "bulkupload", component: BulkuploadComponent },
  { path: "userspecificorder", component: UserspecificorderComponent },
  { path: "designertask", component: DesignertaskComponent },
  { path: "vieworder", component: VieworderComponent },
  { path: "ordertrack", component: OrdertrackComponent },
  { path: "outsourcevendor", component: OutsourcevendorComponent },
  { path: "marketing", component: MarketingComponent },
  { path: "cancelorder", component: CancelorderComponent },
  { path: "custom_search", component: CustomSearchComponent },
  { path: "all_scan_task", component: AllscantaskComponent },
  { path: "deleteoldimages", component: DeleteImageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
