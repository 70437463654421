import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { ToasterService } from '../services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandler } from '@angular/core';



@Component({
  selector: 'app-lable',
  templateUrl: './lable.component.html',
  styleUrls: ['./lable.component.scss']
})
export class LableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    alert("here");
  }

}
