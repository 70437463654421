import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { CommonService } from "../../../services/common.service";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: 'app-marketing',
  templateUrl: 'marketing.component.html',
  styleUrls: ['marketing.component.scss']
})
export class MarketingComponent implements AfterViewInit, OnDestroy, OnInit {
   @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtOptions2: any = {};
  dtTrigger: Subject<any> = new Subject();
  url: string;
  managerlist: Array<any>;
  role: string;
  marketing_managers: Array<any>;
  marketing_manager: string;
  deleteurl: string;

  constructor(private CommonService: CommonService) { }

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    };
    this.allmarketing_manager();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  allmarketing_manager() {
    this.url = "user/role/";
    this.role = "Marketing";
    this.CommonService.getallrecords_by_role(this.url, this.role).subscribe(
      data => {
        this.marketing_managers = data;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next({
            responsive: true
          });
        });
      }
    );
  }

  delete_this(marketing_manager_id, first_name, last_name) {
    this.marketing_manager = first_name + " " + last_name;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete " + this.marketing_manager,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, Please cancel"
    }).then(result => {
      if (result.value) {
        this.deleteurl = "user/delete/";
        this.marketing_manager = first_name + " " + last_name;
        this.CommonService.delete(marketing_manager_id, this.deleteurl).subscribe(
          data => {
            Swal.fire(
              "Deleted!",
              "Your " + this.marketing_manager + "has been deleted.",
              "success"
            );
            this.allmarketing_manager();
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          "Your " + this.marketing_manager + " is safe :)",
          "error"
        );
      }
    });
  }

  recover(marketing_manager_id, first_name, last_name) {
    this.marketing_manager = first_name + " " + last_name;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to recover " + this.marketing_manager,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, recover it!",
      cancelButtonText: "No, Please cancel"
    }).then(result => {
      if (result.value) {
        this.url = "user/recover/";
        this.marketing_manager = first_name + " " + last_name;
        this.CommonService.recover(marketing_manager_id, this.url).subscribe(data => {
          Swal.fire(
            "Recovered!",
            "Your " + this.marketing_manager + "has been recover.",
            "success"
          );
          this.allmarketing_manager();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your cancelled process", "error");
      }
    });
  }

}
