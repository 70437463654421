import { Component, OnInit, Input} from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor( public loginService : LoginService ) {
   }

  ngOnInit() {
  }

}
