import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {CommonService} from "../../services/common.service";
import {ActivatedRoute, Router} from "@angular/router";

import Swal from "sweetalert2/dist/sweetalert2.js";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
declare var $: any;

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
    start : number;
}


@Component({
    selector: "app-productlist",
    templateUrl: "./productlist.component.html",
    styleUrls: ["./productlist.component.scss"]
})
export class ProductlistComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    productRecords: any;
    deleted: any;
    baseURL: any;
    product: any;
    deleteurl: string;
    url: string;
    print_wsp_url: string;
    print_mrp_url: string;
    print_fop_url: string;
    allChecked: boolean;
    singleChecked: boolean;
    all_qr_code: boolean = true;
    selectedAllCheckboxes = [];
    product_count = {};
    indexStart = 1;

    constructor(private CommonService: CommonService,
                private route: ActivatedRoute,
                private router: Router,
                private http: HttpClient) {
    }

    ngOnInit() {
        
    const that = this;
        this.baseURL = environment.APIBaseURL;
        var result = this.CommonService.check_login();
        if (result == true) {
            this.router.navigate(["/login"]);
        }

        this.dtOptions = {
            responsive: true,
            pageLength: 10,
            serverSide : true,
            processing : true,
            ajax : (dataTablesParameters: any, callback) => {
                that.http
                  .post<DataTablesResponse>(
                    environment.APIBaseURL + "product/all/first",
                    dataTablesParameters, {}
                  ).subscribe(resp => {
                if(Object.keys(resp).length > 0) {
                    that.productRecords = resp.data;
                    this.product_count['gow_count'] = resp.data[0].gow_count;
                    this.product_count['sks_count'] = resp.data[0].sks_count;
                    this.product_count['skt_count'] = resp.data[0].skt_count;
                    this.product_count['lh_count'] = resp.data[0].lh_count;
                    this.product_count['kl_count'] = resp.data[0].kl_count;
                    this.product_count['cl_count'] = resp.data[0].cl_count;
                    this.product_count['ku_count'] = resp.data[0].ku_count;
                    this.product_count['sr_count'] = resp.data[0].sr_count;
                    this.indexStart = resp.start+1;
                    callback({
                      recordsTotal: resp.recordsTotal[0].total_products,
                      recordsFiltered: resp.recordsFiltered[0].total_products,
                      data: []
                    });
                } else {
                    that.productRecords = [];
                    this.product_count['gow_count'] = this.product_count['sks_count'] = this.product_count['skt_count'] = this.product_count['lh_count'] = this.product_count['kl_count'] = this.product_count['cl_count'] = this.product_count['ku_count'] = this.product_count['sr_count'] = 0;
                   
                    callback({
                      recordsTotal: 0,
                      recordsFiltered: 0,
                      data: []
                    });
                }
                  });
              },



              columns: [{ name: 'Checkbox',orderable: false },{ data: 'product_id',name: 'product_id' }, { name: 'Image' ,orderable: false}, { name: 'QR code',orderable: false }, { data: 'product_name' ,name: 'product_name' }, { data:'productCategory', name: 'productCategory' }, { data: 'product_description' , name: 'product_description' }, { data: 'sku' ,name: 'sku' }, { data: 'is_deleted', name: 'is_deleted' }, { name: 'Action',orderable: false }]

            };
            

        // this.allProducts();

        // this.dtOptions = {
        //     responsive: true
        // };
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    allProducts() {
        this.dtOptions = {
            responsive: true,
            pageLength: 10,
            serverSide : true,
            processing : true,
            ajax : (dataTablesParameters: any, callback) => {
                this.http
                  .post<DataTablesResponse>(
                    environment.APIBaseURL + "product/all/first",
                    dataTablesParameters, {}
                  ).subscribe(resp => {
                      
                if(Object.keys(resp).length > 0) {
                    this.productRecords = resp.data;
                    this.product_count['gow_count'] = resp.data[0].gow_count;
                    this.product_count['sks_count'] = resp.data[0].sks_count;
                    this.product_count['skt_count'] = resp.data[0].skt_count;
                    this.product_count['lh_count'] = resp.data[0].lh_count;
                    this.product_count['kl_count'] = resp.data[0].kl_count;
                    this.product_count['cl_count'] = resp.data[0].cl_count;
                    this.product_count['ku_count'] = resp.data[0].ku_count;
                    this.product_count['sr_count'] = resp.data[0].sr_count;
                    this.indexStart = resp.start+1;
                    callback({
                      recordsTotal: resp.recordsTotal[0].total_products,
                      recordsFiltered: resp.recordsFiltered[0].total_products,
                      data: []
                    });
                } else {
                    this.productRecords = [];
                    this.product_count['gow_count'] = this.product_count['sks_count'] = this.product_count['skt_count'] = this.product_count['lh_count'] = this.product_count['kl_count'] = this.product_count['cl_count'] = this.product_count['ku_count'] = this.product_count['sr_count'] = 0;
                   
                    callback({
                      recordsTotal: 0,
                      recordsFiltered: 0,
                      data: []
                    });
                }
                    this.rerender();
                  });
              },
              columns: [{ name: 'Checkbox',orderable: false },{ data: 'product_id',name: 'product_id' }, { name: 'Image',orderable: false }, { name: 'QR code',orderable: false }, { data: 'product_name' ,name: 'product_name' }, { data:'productCategory', name: 'productCategory' }, { data: 'product_description' , name: 'product_description' }, { data: 'sku' ,name: 'sku' }, { data: 'is_deleted', name: 'is_deleted' }, { name: 'Action',orderable: false }]
            };
    }

    firstAllProducts() {

        
    }

    delete_this(product_id, product_name) {
        this.product = product_name;
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete " + this.product,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, Please cancel"
        }).then(result => {
            if (result.value) {
                this.deleteurl = "product/delete/";
                this.product = product_name;
                this.CommonService.delete(product_id, this.deleteurl).subscribe(
                    data => {
                        Swal.fire(
                            "Deleted!",
                            "Your " + this.product + "has been deleted.",
                            "success"
                        );
                        this.allProducts();
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your " + this.product + " is safe :)", "error");
            }
        });
    }

    // printQRCode(data) {
    //     this.CommonService.printQRCode( data, 'qrcode/print_qr_code').subscribe(result=> {
    //         console.log(result)
    //     });
    // }

    recover(product_id, product_name) {
        this.product = product_name;
        Swal.fire({
            title: "Are you sure?",
            text: "You want to recover " + this.product,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, recover it!",
            cancelButtonText: "No, Please cancel"
        }).then(result => {
            if (result.value) {
                this.url = "product/recover/";
                this.product = product_name;
                this.CommonService.recover(product_id, this.url).subscribe(data => {
                    Swal.fire(
                        "Recovered!",
                        "Your " + this.product + "has been recovered.",
                        "success"
                    );
                    this.allProducts();
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "You Cancelled process", "error");
            }
        });
    }

    generate_qr_code_url() {
        if (this.selectedAllCheckboxes.length > 0) {
            this.print_wsp_url = this.baseURL + "qrcode/print_wsp_qr_code?id=";
            this.print_mrp_url = this.baseURL + "qrcode/print_mrp_qr_code?id=";
            this.print_fop_url = this.baseURL + "qrcode/print_fop_qr_code?id=";
            var params = [];
            this.selectedAllCheckboxes.forEach(function (singleRecord) {
                params.push(singleRecord);
            });

            this.all_qr_code = false;
            this.print_wsp_url = this.print_wsp_url + params;
            this.print_mrp_url = this.print_mrp_url + params;
            this.print_fop_url = this.print_fop_url + params;
        } else {
            this.all_qr_code = true;
        }
    }

    checkedAll(event) {
        if (event.target.checked) {
            this.allChecked = true;
            for (let singleRecord of this.productRecords) {
                this.selectedAllCheckboxes.push(singleRecord.product_id);
            }
        } else {
            this.allChecked = false;
            this.selectedAllCheckboxes = [];
        }
        this.generate_qr_code_url();
    }

    checkedSingle(event, product_id) {
        let index = this.selectedAllCheckboxes.indexOf(product_id);
        if (event.target.checked) {
            // console.log(index);
            if (index === -1) {
                this.selectedAllCheckboxes.push(product_id);
            } else {
                this.selectedAllCheckboxes.splice(index, 1);
            }
        } else {
            this.selectedAllCheckboxes.splice(index, 1);
            console.log("single checkbox unchecked");
        }
        this.generate_qr_code_url();
        //console.log(this.selectedAllCheckboxes);
    }
}
