import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ToasterService } from "../../services/toaster.service";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../../environments/environment";

declare var swal: any;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  start : number;
}



@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"]
})
export class OrdersComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  allordersList: any;
  customerdeleteurl: string;
  clientname: string;
  client_id: number;
  deleted: any;
  url: string;

  total_pendings:number = 0;
  total_dispatched:number = 0;
  total_completed:number = 0;
  total_cancelled:number = 0;
  total_pending_order:number = 0;
  total_dispatched_order:number = 0;
  total_completed_order:number = 0;
  total_cancelled_order:number = 0;

  indexStart = 1;

  constructor(
    private CommonService: CommonService,
    private ToasterService: ToasterService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    const that = this;
    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }

    // this.fetchAllordersList();
    this.dtOptions = {
      responsive: true,
      pageLength: 10,
      serverSide : true,
      processing : true,
      ajax : (dataTablesParameters: any, callback) => {
          that.http
            .post<DataTablesResponse>(
              environment.APIBaseURL + "customer_orders/all/first",
              dataTablesParameters, {}
            ).subscribe(resp => {
              console.log(resp);
              
          if(Object.keys(resp).length > 0) {
              //console.log(data);
              this.allordersList = resp.data;
              var total_pendings = 0;
              var total_cancelled = 0;
              var total_completed = 0;
              var total_dispatched = 0;
              var total_pending_order = 0;
              var total_cancelled_order = 0;
              var total_completed_order = 0;
              var total_dispatched_order = 0;
              this.indexStart = resp.start+1;

              this.allordersList.forEach(function (singleRecord) {
                  total_pendings = total_pendings + singleRecord.pending_orders;
                  total_cancelled = total_cancelled + singleRecord.cancelled_orders;
                  total_completed = total_completed + singleRecord.completed_orders;
                  total_dispatched = total_dispatched + singleRecord.dispatch_orders;
                  total_pending_order = singleRecord.total_pending_orders;
                  total_cancelled_order = singleRecord.total_cancelled_orders;
                  total_completed_order = singleRecord.total_completed_orders;
                  total_dispatched_order = singleRecord.total_dispatch_orders;
              });

              this.total_pendings = total_pendings;
              this.total_cancelled = total_cancelled;
              this.total_completed = total_completed;
              this.total_dispatched = total_dispatched;
              this.total_pending_order = total_pending_order;
              this.total_cancelled_order = total_cancelled_order;
              this.total_completed_order = total_completed_order;
              this.total_dispatched_order = total_dispatched_order;
              callback({
                recordsTotal: resp.recordsTotal[0].total_products,
                recordsFiltered: resp.recordsFiltered[0].total_products,
                data: []
              });
          } else {
              that.allordersList = [];
              this.total_pendings = this.total_cancelled = this.total_completed = this.total_dispatched = 0;
              this.total_pending_order = this.total_cancelled_order = this.total_completed_order = this.total_dispatched_order = 0;
             
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: []
              });
          }
            });
        },



        columns: [{ name: 'SR',orderable: false },{ data: 'designer_first_name',name: 'designer_first_name' },{ data: 'order_id',name: 'order_id' }, { name: 'Assign Designer' ,orderable: false}, { data: 'date_order_placed' ,name: 'date_order_placed' }, { data: 'due_date', name: 'due_date' }, { name: 'Action',orderable: false }]

      };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchAllordersList() {
    this.dtOptions = {
      responsive: true,
      pageLength: 10,
      serverSide : true,
      processing : true,
      ajax : (dataTablesParameters: any, callback) => {
          this.http
            .post<DataTablesResponse>(
              environment.APIBaseURL + "customer_orders/all/first",
              dataTablesParameters, {}
            ).subscribe(resp => {
          if(Object.keys(resp).length > 0) {
              //console.log(data);
              this.allordersList = resp.data;
              var total_pendings = 0;
              var total_cancelled = 0;
              var total_completed = 0;
              var total_dispatched = 0;
              var total_pending_order = 0;
              var total_cancelled_order = 0;
              var total_completed_order = 0;
              var total_dispatched_order = 0;
              
              this.indexStart = resp.start+1;

              this.allordersList.forEach(function (singleRecord) {
                  total_pendings = total_pendings + singleRecord.pending_orders;
                  total_cancelled = total_cancelled + singleRecord.cancelled_orders;
                  total_completed = total_completed + singleRecord.completed_orders;
                  total_dispatched = total_dispatched + singleRecord.dispatch_orders;
                  total_pending_order = singleRecord.total_pending_orders;
                  total_cancelled_order = singleRecord.total_cancelled_orders;
                  total_completed_order = singleRecord.total_completed_orders;
                  total_dispatched_order = singleRecord.total_dispatch_orders;
              });

              this.total_pendings = total_pendings;
              this.total_cancelled = total_cancelled;
              this.total_completed = total_completed;
              this.total_dispatched = total_dispatched;
              this.total_pending_order = total_pending_order;
              this.total_cancelled_order = total_cancelled_order;
              this.total_completed_order = total_completed_order;
              this.total_dispatched_order = total_dispatched_order;
              callback({
                recordsTotal: resp.recordsTotal[0].total_products,
                recordsFiltered: resp.recordsFiltered[0].total_products,
                data: []
              });
          } else {
              this.allordersList = [];
              this.total_pendings = this.total_cancelled = this.total_completed = this.total_dispatched = 0;
              this.total_pending_order = this.total_cancelled_order = this.total_completed_order = this.total_dispatched_order = 0;
             
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: []
              });
          }
            });
        },



        columns: [{ name: 'SR',orderable: false },{ data: 'designer_first_name',name: 'designer_first_name' },{ data: 'order_id',name: 'order_id' }, { name: 'Assign Designer' ,orderable: false}, { data: 'date_order_placed' ,name: 'date_order_placed' }, { data: 'due_date', name: 'due_date' }, { name: 'Action',orderable: false }]

      };
  }
}
