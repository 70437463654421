import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { BrowserQRCodeReader } from "@zxing/library";
import { CommonService } from "../services/common.service";
import { ProductService } from "../services/product.service";
import Result from "@zxing/library/esm5/core/Result";
const codeReader = new BrowserQRCodeReader();
let selectedDeviceId;
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2/dist/sweetalert2.js";

declare var $: any;

@Component({
  selector: "app-userscantask",
  templateUrl: "./userscantask.component.html",
  styleUrls: ["./userscantask.component.scss"]
})
export class UserscantaskComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  scan_details: boolean = false;
  joblist: boolean = false;
  data: any;
  order_product_details: any = {};
  product: any;
  tasklist: any;
  order_item_details: any;
  localData: any;
  imgurls: any;
  out_source_vendors = [];
  selected_outsource_vendor: any;
  isSelectOutsourseVendor: boolean = false;

  uploadImageOptions = {
    items: 1,
    margin: 0,
    dots: false,
    nav: true,
    navText: [
      '<button type="button" class="btn slider-left-btn"><i class="fa fa-angle-left"></i></button>',
      '<button type="button" class="btn slider-right-btn"><i class="fa fa-angle-right"></i></button>'
    ],
    autoplay: false,
    loop: false,
    lazyLoad: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      769: {
        items: 1
      }
    }
  };

  constructor(
    private CommonService: CommonService,
    private ProductService: ProductService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dtOptions = {
      responsive: true
    };

    var result = this.CommonService.check_login();
    if (result == true) {
      this.router.navigate(["/login"]);
    }
    this.localData = {
      user_id: localStorage.getItem("user_id"),
      user_role: localStorage.getItem("user_role"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name")
    };

    if (this.localData.user_role !== "Dispatch") {
      this.takslist();
    }

    if (localStorage.getItem("user_role") === "OutSource") {
      this.get_out_source_vendors();
    }

    codeReader
      .getVideoInputDevices()
      .then(videoInputDevices => {
        /*const sourceSelect = document.getElementById('sourceSelect');*/
        selectedDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length >= 1) {
          videoInputDevices.forEach(element => {
            const sourceOption = document.createElement("option");
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
          });
        }
      })
      .catch(err => {
        console.error(err);
      });

    $('[data-fancybox="gallery"]').fancybox({
      buttons: ["zoom"]
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  scan() {
    codeReader
      .decodeFromInputVideoDevice(undefined, "video")
      .then(result => {
        var data = result["text"];
        this.order_product_details = JSON.parse(data);
        this.ProductService.scanned(
          "order/track/order_item_scan_details/",
          this.order_product_details.order_item_id
        ).subscribe(product_details => {
          this.order_item_details = product_details[0];

          if (this.order_item_details.product_images) {
            this.imgurls = this.order_item_details.product_images;
          }
          this.scan_details = true;
          codeReader.reset();
        });
      })
      .catch(err => {
        console.error(err);
        document.getElementById("result").textContent = err;
      });
    console.log(
      `Started continous decode from camera with id ${selectedDeviceId}`
    );
  }

  add_to_task() {
    if (this.out_source_vendors.length > 0) {
      if (this.selected_outsource_vendor) {
        this.isSelectOutsourseVendor = false;
        var out_source_vendor_details = this.out_source_vendors.filter(
          obj => obj.user_id === parseInt(this.selected_outsource_vendor)
        );
        this.data = {
          user_id: localStorage.getItem("user_id"),
          user_role: localStorage.getItem("user_role"),
          // "tracking_id" : this.order_item_details.tracking_id,
          order_item_id: this.order_item_details.order_item_id,
          order_id: this.order_item_details.order_id,
          outsource_vendor_name:
            out_source_vendor_details[0].first_name +
            " " +
            out_source_vendor_details[0].last_name,
          outsource_vendor: true
        };
      } else {
        this.isSelectOutsourseVendor = true;
      }
    } else {
      this.data = {
        user_id: localStorage.getItem("user_id"),
        user_role: localStorage.getItem("user_role"),
        // "tracking_id" : this.order_item_details.tracking_id,
        order_item_id: this.order_item_details.order_item_id,
        order_id: this.order_item_details.order_id,
        outsource_vendor: false
      };
      console.log("outside vendor");
    }

    if (this.data.user_role == "QtyChecker") {
      this.data.user_role = "Completed";
    }
    this.CommonService.update(
      this.data,
      "order/track/update/status/" + this.order_item_details.order_item_id
    ).subscribe(result => {
      if (result) {
        this.takslist();
      }
      Swal.fire("Task Assigned Successfully!", "", "success");
    });
  }

  reset() {
    this.order_item_details = {};
    this.scan_details = false;
    codeReader.reset();
    // console.log('Reset.')
  }

  takslist() {
    this.CommonService.tasklist(localStorage.getItem("user_id")).subscribe(
      result => {
        this.tasklist = result;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next({
            responsive: true
          });
        });

        this.joblist = true;
        this.scan_details = false;
        this.reset();
      }
    );
  }

  get_out_source_vendors() {
    this.CommonService.getallrecords_by_role(
      "user/role/",
      "Out Source Vendor"
    ).subscribe(data => {
      this.out_source_vendors = data;
      console.log(this.out_source_vendors);
    });
    //
  }

  orderDetails(order_item_id) {
    this.ProductService.scanned(
      "order/track/order_item_scan_details/",
      order_item_id
    ).subscribe(product_details => {
      this.order_item_details = product_details[0];
      if (this.order_item_details.product_images) {
        this.imgurls = this.order_item_details.product_images;
        console.log(this.imgurls);
      }
      this.scan_details = true;
    });
  }

  select_outsource_vendor(data) {
    this.selected_outsource_vendor = data;
    console.log(this.selected_outsource_vendor);
  }
}
